import React from 'react'

/**
 * This component used to contain register/login/logout links, hence the name.
 * These are now oboslete since the website does not maintain user management anymor
 */
const LoginMenu = () => {
    return (
        <div className='login-menu' data-o-grid-colspan='1 M4 Mpush4 L3 Lpush3'>
            <ul className='login-menu__link-container login-menu__list'>
                <li className='login-menu__link--contact login-menu__list-element'>
                    <a
                        data-trackable='Contact Link'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--right'
                        href={`/form/contact-us/${encodeURI('General Enquiry')}`}
                    >
                        Contact us
                    </a>
                </li>
                <li className='login-menu__list-element'>
                    <a
                        data-trackable='Advertise with us'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--right login-menu__separator--left-space'
                        href={`/products-and-services/advertising-opportunities`}
                    >
                        Advertise with us
                    </a>
                </li>
                <li className='login-menu__list-element'>
                    <a
                        data-trackable='Newsletter'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--left-space'
                        href={`/newsletter-signup`}
                    >
                        Newsletter sign-up
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default LoginMenu
