import React from 'react'

import { useCurrency } from '../../context/CurrencyContext'

import { __w, transformLinks } from '../../utils/helpers'

interface HeroProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    product: any
}

export const Hero = ({ product }: HeroProps) => {
    const { currencies } = useCurrency()
    const { btnText, btnLink, trackable } = transformLinks(product)

    const getDownloadLink = (currency: string) =>
        product.mediaPackBtn.replace('%CURRENCY%', currency)

    return (
        <div className='hero' data-trackable-section='hero'>
            <div className='details'>
                <h3>{product.unit}</h3>
                <h1>{__w(product.title, true)}</h1>
                <h2>{__w(product.tagline)}</h2>
                <p>{__w(product.description)}</p>
                {product.testimonial && (
                    <blockquote>
                        <img
                            src={product.testimonial.image}
                            alt={product.testimonial.by}
                        />
                        <div className='quote'>
                            <p>{__w(product.testimonial.quote)}</p>
                            <cite>
                                <strong>{__w(product.testimonial.by)}</strong>
                                <br />
                                {__w(product.testimonial.location)}
                            </cite>
                        </div>
                    </blockquote>
                )}
                {product.items && (
                    <ul className='grey-text'>
                        {product.items.map((item: string, index: number) => (
                            <li key={index}>{__w(item)}</li>
                        ))}
                    </ul>
                )}
                {product.afterItems && (
                    <p className='w-100 grey-text'>{__w(product.afterItems)}</p>
                )}
                <div className='buttons'>
                    {product.mediaPackBtn && (
                        <div className='dropdown colored'>
                            <div className='label'>Download media pack</div>
                            <div className='dropdown-content'>
                                {currencies.map((item, index) => (
                                    <a
                                        key={index}
                                        href={getDownloadLink(item)}
                                        data-trackable='download-media-pack-cta'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {item}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                    <a
                        href={btnLink}
                        data-trackable={`hero-${trackable}-cta`}
                        className={`button ${!product.mediaPackBtn && 'colored'}`}
                    >
                        {btnText}
                    </a>
                    {product.loginBtn && (
                        <a
                            href={product.loginBtn}
                            data-trackable={`hero-login-cta`}
                            target='_blank'
                            className='button'
                            rel='noreferrer'
                        >
                            Log in to account
                        </a>
                    )}
                </div>
            </div>
            <div className='preview'>
                <img src={product.image} alt={product.title} />
            </div>
        </div>
    )
}

export default Hero
