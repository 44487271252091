import React, { FC } from 'react'

import { Article } from '../../types'

const ArticleBanner: FC<{
    article: Article
    colour: string
    children?: React.ReactNode
    fullWidth?: boolean
}> = ({ colour, article, children, fullWidth = false }) => {
    let partnerContent = null
    if (article && article.partner_content) {
        partnerContent = article.partner_content
    }

    const backgroundColor = partnerContent ? 'fdi-advertorial-light' : colour

    return (
        <div
            className='article-header__banner o-grid-container--bleed'
            style={{ backgroundColor: backgroundColor || 'transparent' }}
        >
            <div className={!fullWidth ? 'o-grid-container' : ''}>
                {children}
            </div>
        </div>
    )
}

export default ArticleBanner
