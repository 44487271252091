/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

interface ArticleTeaserWithAuthorProps {
    teaser: string
    white: boolean
    authorName: string
    authorImage: string
    authorImageFilter?: string
}

const ArticleTeaserWithAuthor: FC<ArticleTeaserWithAuthorProps> = (
    props: ArticleTeaserWithAuthorProps = {
        teaser: '',
        white: false,
        authorName: '',
        authorImage: '',
        authorImageFilter: '',
    }
) => {
    const text = props.teaser.replace(/<p>|<\/p>/g, '')
    const color = props.white ? 'white' : '$fdi-off-black'

    return (
        <div
            className='article-teaser article-teaser--with-author o-grid-row'
            style={
                {
                    '--article-teaser-color': color,
                    '--author-image-filter': props.authorImageFilter,
                } as any
            }
        >
            <div
                className='article-teaser__data'
                data-o-grid-colspan='12 Mpush1 M10 L7'
            >
                <h3
                    className='article-teaser__text article-teaser__text--mobile'
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <div className='article-teaser__author'>
                    <div className='article-teaser__author-text'>
                        <h3
                            className='article-teaser__text article-teaser__text--no-mobile'
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                        <h3
                            className='article-teaser__author-name'
                            dangerouslySetInnerHTML={{
                                __html: props.authorName,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleTeaserWithAuthor
