import React from 'react'

import MPU from '../MPU'

export interface MPUContainerProps {
    className?: string
    type: string
}

const MPUContainer = ({ className = '', type }: MPUContainerProps) => {
    return (
        <div
            className={`o-grid-container--bleed homepage-mpu ${className}`}
            data-trackable-section='mpu'
        >
            <div className='mpu__row'>
                <div
                    className='mpu__container'
                    data-o-grid-colspan='Scenter S12'
                >
                    <div className='mpu__text-container'>
                        <p className='mpu__text'>Advertisement</p>
                    </div>
                    <div className='mpu'>
                        <MPU type={type} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MPUContainer
