import React, { FC } from 'react'

import { POWERED_BY_OPTIONS } from '../../assets/constants'

const PoweredByLabel: FC<{ poweredBy: string }> = ({ poweredBy }) => {
    if (poweredBy in POWERED_BY_OPTIONS) {
        const poweredBySlug = POWERED_BY_OPTIONS[
            poweredBy as keyof typeof POWERED_BY_OPTIONS
        ]
            .toLowerCase()
            .replace(' ', '-')

        return (
            <>
                <a
                    className={`powered-by-label powered-by-label--${poweredBySlug}`}
                    href={`/${poweredBySlug}`}
                    data-trackable={'Powered By label'}
                >
                    {POWERED_BY_OPTIONS
                        ? `Powered by ${POWERED_BY_OPTIONS[poweredBy as keyof typeof POWERED_BY_OPTIONS]}`
                        : ''}
                </a>
            </>
        )
    }

    return null
}

export default PoweredByLabel
