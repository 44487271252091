/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect } from 'react'

import ArticleBanner from '../article-banner/ArticleBanner'
import { EventHub } from '../../types'
import EventsHubHeader from '../events-hub-header/EventsHubHeader'
import EventsHubRelatedArticles from '../events-hub-related-articles/EventsHubRelatedArticles'
import EventsPromo from '../events-promo/EventsPromo'
import { updateAdsConfig } from '../utils/updateAdsConfig'
import { fireEvent, initTracking } from '../tracking/Tracking'
import TrackingContext from '../context/TrackingContext'

interface EventHubProps {
    event: EventHub
    url: string
}

const EventHub: FC<EventHubProps> = ({ event, url }) => {
    const { events, setEvents } = useContext(TrackingContext)

    useEffect(() => {
        initTracking('event-hub')
    }, [])

    useEffect(() => {
        if (events.length > 0) {
            events.forEach(event => {
                const detail = {
                    category: event.category,
                    action: event.actionType,
                    ...event.data,
                }
                fireEvent(detail, true)
            })

            setEvents([])
        }
    }, [events])

    useEffect(() => {
        updateAdsConfig({
            uuid: event.id,
            asset_type: 'event_hub',
            pt: 'art',
            loggedin: 'Yes',
        })
    }, [event.id])

    return (
        <>
            <ArticleBanner article={event as any} colour='#000'>
                <EventsHubHeader {...event} url={url} />
            </ArticleBanner>
            <div className='o-grid-container'>
                <EventsHubRelatedArticles
                    articles={event.relatedArticles as any}
                />
                <EventsPromo />
            </div>
        </>
    )
}

export default EventHub
