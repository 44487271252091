import React from 'react'

import ButtonLink from '../button-link/ButtonLink'

const EventsPromo = () => {
    return (
        <div className='events-promo'>
            <h1 className='events-promo__supertext'>Events</h1>
            <img
                className='events-promo__image'
                data-o-grid-colspan='0 M9 Mpush3'
            />
            <div className='events-promo__content o-grid-row'>
                <div
                    className='events-promo__text'
                    data-o-grid-colspan='center 10 Muncenter M5'
                >
                    <h3 className='events-promo__heading'>
                        Meet investors face-to-face
                    </h3>
                    <p className='events-promo__details'>
                        Our suite of events brings investment promoters into
                        direct contact with carefully vetted potential
                        investors.
                    </p>
                    <ButtonLink
                        title=''
                        link='/form/contact-us/Events'
                        className='events-promo__button'
                    >
                        <div className='button-link__title'>Learn more</div>
                        <div className='events-promo__alt-title'>
                            Create your own event with fDi
                        </div>
                    </ButtonLink>
                </div>
                <img
                    className='events-promo__image'
                    data-o-grid-colspan='12 M0'
                />
            </div>
        </div>
    )
}

export default EventsPromo
