/* eslint-disable */
import React, { useState, useEffect, FC } from 'react'
import { renderToString } from 'react-dom/server'
import { EMBEDDED_TAGS_REGEX } from '../../assets/constants'
import ACastIframe from '../acast-iframe/ACastIframe'
import ArticleRanking from '../article-ranking/ArticleRanking'
import MPUContainer from '../mpu/mpu-container/MPUContainer'
import Attachment from '../attachment/Attachment'
import Table from '../table/Table'
import { matchAllIndex } from '../../components/utils/helpers'
import writeLog from '../../utils/logging'
import MostReadArticles from '../article-most-read/ArticleMostRead'
import { Article, MostReadArticle } from '../../types'
import { makeGraphQLRequest } from '../../utils/graphqlClientSide'
import NewsletterBox from '../newsletter-box/NewsletterBox'
import SpecialReportBox from '../special-report-box/SpecialReportBox'

const ArticleBody: FC<{
    article: Article
    mostRead: Array<MostReadArticle>
    genre: string
    colour: string | null
    environment: {
        apolloServerUrl: string
    }
}> = ({ article, mostRead, genre, colour, environment }) => {
    const {
        body_text: bodyText,
        // id: articleId,
        attachment,
        ranking,
        partner_content,
        introduction,
    } = article

    const bannerColour = colour || '#222'

    const quotesColour = '#4995D1'
    const bodyNoInlineStyle = bodyText?.replace(
        /((width|height)="[0-9]+")|(style=".{0,20}")/g,
        ''
    )
    const [body, setBody] = useState(bodyNoInlineStyle)

    /**
     * SHOULD BE FIXED
     *
     * An ugly way to make SHORTHAND script work in Article Body component.
     * Onclick event is attached to the button, but REACT remove these event by re-rendering its state.
     *
     * This component should be re-written to support JS in article text.
     */

    useEffect(() => {
        ;(function ($) {
            function loadStory(event: any) {
                if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    return true
                }
                var storyURL = $(this).attr('href')
                var storyProtocol = storyURL.split('/')[0]
                var pageProtocol = window.location.href.split('/')[0]
                if (pageProtocol === 'https:' && storyProtocol === 'http:') {
                    return true
                }
                event.preventDefault()
                var $window = $(window)
                var $body = $('body')
                var $iframe = $(
                    '<iframe width="100%" height="100%" style="position:fixed; top:0; left:0; visibility: hidden;"></iframe>'
                )
                $iframe.prop('src', storyURL)
                var $placeholder = $("<span id='sh_placeholder'></span>")
                var $el = $(this).closest('[data-sh-embed]')
                var $close = $(
                    '<span class="sh-embed-close"><span>&#10006;</span> Close</span>'
                )
                var position = {
                    origin: $el.offset(),
                    width: $el.width(),
                    height: $el.height(),
                }
                $close.on('click', closeStory)
                $body.css({ overflow: 'hidden' })
                $placeholder.css({ width: $el.css('height'), display: 'block' })
                $placeholder.insertBefore($el)
                $body.append($el)
                $el.css({ zIndex: 99999 })
                $el.addClass('selected-embed')
                $el.data('position', position)
                $el.find('.sh-embed-meta').fadeOut('fast')
                $el.append($iframe)
                //Set the embed box to be absolutely positioned
                $el.css({
                    position: 'absolute',
                    top: position.origin.top,
                    left: 0,
                    height: position.height,
                })
                //Animate to full screen
                $el.animate(
                    {
                        top: $window.scrollTop(),
                        left: 0,
                        height: $window.height(),
                        zIndex: 99999,
                    },
                    500,
                    function () {
                        $iframe.css({ visibility: 'visible' })
                        $iframe.fadeIn()
                        $el.append($close).fadeIn()
                    }
                )
                var agent = navigator.userAgent
                var isDesktopSafari =
                    /Safari/i.test(agent) && !/Chrome/i.test(agent)
                // Fix locked scroll on Safari. Issue: #3587 & #3840
                if (isDesktopSafari) {
                    setTimeout(function () {
                        $el.css({ overflow: 'scroll' })
                    }, 600)
                }
                $window.on('resize.iframe', function () {
                    $el.height($window.height())
                    if (isDesktopSafari && $el.css('overflow') !== 'hidden') {
                        // On Safari, the launched story requires the container to be set to
                        // overflow: scroll. As soon as this container is resized, the
                        // inner scroll becomes active, so this attribute on the container
                        // is removed.
                        $el.css({ overflow: 'hidden' })
                    }
                })
            }
            function closeStory(event: any) {
                event.preventDefault()
                var $window = $(window)
                var $el = $('div.selected-embed')
                var originalPosition = $el.data('position')
                var $close = $el.find('.sh-embed-close')
                var $iframe = $el.find('iframe')
                $el.removeClass('selected-embed')
                $iframe.fadeOut('fast')
                $close.fadeOut('fast')
                //Set the now relative positioned area to where it would be if it was absolutely positioned
                $el.css({
                    zIndex: 0,
                    height: 'auto',
                    width: '100%',
                })
                //Animate down to the small size that it was originally
                $el.animate(
                    {
                        height: 'auto',
                    },
                    500,
                    function () {
                        $('body').css({ overflow: 'auto' })
                        $iframe.remove()
                        $close.remove()
                        $el.find('.sh-embed-meta').fadeIn()
                        var $placeholder = $('#sh_placeholder')
                        $el.insertAfter($placeholder)
                        $placeholder.remove()
                        $el.css({
                            position: 'relative',
                            top: 'auto',
                            left: 'auto',
                        })
                    }
                )
                //Stop watching for browser height changes
                $window.off('resize.iframe')
            }
            if ((window as any).jQuery) {
                $('.sh-embed-btn[data-embed-untouched]').each(function () {
                    $(this).removeAttr('data-embed-untouched')
                    $(this).on('click', loadStory)
                })
            }
        })((window as any).jQuery)
    }, [])

    /** Replaces custom table tags with rendered Table components containing data from relevant Table objects */
    useEffect(() => {
        const podcastTags = body.match(EMBEDDED_TAGS_REGEX.PODCAST)
        if (podcastTags && podcastTags.length > 0) {
            let bodyWithPodcasts = body
            podcastTags.forEach(podcastTag => {
                const podcastId = getAttributeFromEmbeddedTag(
                    podcastTag,
                    'data-acastpodcast-id'
                )
                const renderedPodcast = renderToString(
                    <ACastIframe podcastId={podcastId} />
                )
                bodyWithPodcasts = bodyWithPodcasts.replace(
                    podcastTag,
                    renderedPodcast
                )
            })

            setBody(bodyWithPodcasts)
        }

        const tables = Array.from(
            document.body.querySelectorAll('[data-o-component~="o-table"]')
        )

        if (tables.length > 0) {
            const OTable = require('@financial-times/o-table/browser')
            tables.forEach(table => {
                OTable.init(table)
            })
        }

        const tableTags = body.match(EMBEDDED_TAGS_REGEX.TABLE)

        if (tableTags && tableTags.length > 0) {
            const tableObjectsIds = tableTags.map(tag =>
                parseInt(getAttributeFromEmbeddedTag(tag, 'data-table-id'), 10)
            )

            getTableObjectsByIds(tableObjectsIds)
                .then(res => res.data.data.tablesById)
                .then(tableObjects => {
                    if (tableObjects && tableObjects.length > 0) {
                        let bodyWithTables = body

                        tableTags.forEach(tableTag => {
                            const tableObjectId = getAttributeFromEmbeddedTag(
                                tableTag,
                                'data-table-id'
                            )
                            const tableObject = tableObjects.find(
                                (tableObject: any) =>
                                    tableObject.id === tableObjectId
                            )
                            const renderedTable =
                                getRenderedTableFromTableObject(tableObject)

                            bodyWithTables = bodyWithTables.replace(
                                tableTag,
                                renderedTable
                            )
                        })

                        setBody(bodyWithTables)
                    }
                })
                .catch(e => writeLog(e))
        }

        function getTableObjectsByIds(ids: any) {
            const query = ` query Tables($ids: [Int!]) {
                tablesById(ids: $ids) {
                  id
                  title
                  csv
                  source
                }
              }`
            const variables = {
                ids,
            }

            return makeGraphQLRequest(
                environment.apolloServerUrl,
                query,
                variables,
                'tablesById'
            )
        }

        function getRenderedTableFromTableObject(tableObject: any) {
            return tableObject
                ? renderToString(
                      <Table
                          title={tableObject.title}
                          csv={tableObject.csv}
                          source={tableObject.source}
                      />
                  )
                : ''
        }
    }, [body])

    function getAttributeFromEmbeddedTag(embeddedTag: any, attr: any) {
        const attrIndex = embeddedTag.indexOf(attr)

        return embeddedTag.slice(
            embeddedTag.indexOf('"', attrIndex) + 1,
            embeddedTag.lastIndexOf('"')
        )
    }
    
    function injectShorthandInBody(body: string): string {
        if (!body) return ''
        if (!article.shorthandEmbed) return body
        
        const shorthandEmbed = article.shorthandEmbed 
     
        return body + shorthandEmbed
    }

    function getBodyWithMPU() {
        const renderedMPU = renderToString(
            <div className='article--mpu'>
                {' '}
                <MPUContainer type='mid-mobile' />{' '}
            </div>
        )
        return (body?.match(/<p>/g) || []).length > 2
            ? injectMPU(renderedMPU)
            : body + renderedMPU
    }
    
    function injectComponentsIntoBody() {
        return (
            getBodyWithAttachment(injectShorthandInBody(getBodyWithMPU())) +
            (article.specialReport
                ? renderToString(<SpecialReportBox {...article.specialReport} />)
                : '') +
            (renderToString(<NewsletterBox />))
        ) // after P1 = attachment, after P2 = MPU (tablet)
    }

    function getBodyWithAttachment(articleBody: any) {
        if (attachment) {
            const attachmentComponent = renderToString(
                <Attachment {...attachment} />
            )
            const paragraphOneIndex = articleBody.indexOf('</p>')

            const attachmentPosition = paragraphOneIndex + 4
            return (
                articleBody.slice(0, attachmentPosition) +
                attachmentComponent +
                articleBody.slice(attachmentPosition)
            )
        }
        return articleBody
    }

    function injectMPU(renderedMPU: any) {
        const matches = matchAllIndex(/<\/p>/g, body, 8)
        const MPUPosition = matches[1] + 4 //after second paragraph

        if ((body.match(/<p>/g) || []).length > 8) {
            const secondMPUPosition = matches[7] + 4
            const secondMPU = renderToString(
                <div className='article--mpu'>
                    {' '}
                    <MPUContainer type='mid1-mobile' />{' '}
                </div>
            )

            return (
                body.slice(0, MPUPosition) +
                renderedMPU +
                body.slice(MPUPosition, secondMPUPosition) +
                secondMPU +
                body.slice(secondMPUPosition)
            )
        } else {
            return (
                body.slice(0, MPUPosition) +
                renderedMPU +
                body.slice(MPUPosition)
            )
        }
    }

    useEffect(() => {
        if ((window as any).videojs) {
            const players = (window as any).videojs.getPlayers()
            const playerNames = Object.keys(players)
            const playersLength = playerNames.length
            for (let i = 0; i < playersLength; i++) {
                const playerName = playerNames[i]
                players[playerName].on('loadstart', () => {
                    const videoTitle =
                        players[playerName].catalog &&
                        players[playerName].catalog.data &&
                        players[playerName].catalog.data.name
                    const videoId =
                        players[playerName].catalog &&
                        players[playerName].catalog.data &&
                        players[playerName].catalog.data.id
                    const videoContainers = document.querySelectorAll(
                        `.ftVideoFrontpageCustomPlayer-${videoId}`
                    )
                    const containersLength = videoContainers.length
                    for (let j = 0; j < containersLength; j++) {
                        const title = document.createElement('span')
                        title.classList.add(`video-title`)
                        title.innerHTML = videoTitle
                        videoContainers[j]?.parentElement?.appendChild(title)
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
         // When there are graphics in an article there is a problem loading them using react hydration
        // when using script tag the visualisation doesn't load the iframe. In order to load the iframes do this after mount.
        // for documentation check https://help.flourish.studio/article/145-how-to-embed-flourish-charts-in-your-cms
        const flourishElements = document.querySelectorAll(
            '.flourish-embed[data-src^="visualisation/"], .flourish-embed[data-src^="story/"]'
        );

        flourishElements.forEach(flourishElement => {
            const dataSrc = flourishElement.getAttribute('data-src')
            if (!dataSrc) return;

            const flourishChartType = dataSrc?.split('/')[0]
            const chartId = dataSrc
                ?.replace(`${flourishChartType}/`, '')
                ?.split('?')
                ?.shift()

            if (!flourishElement.querySelector('iframe')) {
                const iframeElement = document.createElement('iframe')
                iframeElement.src = `https://flo.uri.sh/${flourishChartType}/${chartId}/embed?auto=1`
                iframeElement.width = '100%'
                iframeElement.setAttribute('scrolling', 'no')
                iframeElement.setAttribute('frameborder', '0')

                flourishElement.appendChild(iframeElement)
            }
        })
    }, [])

    const partnerContentClass = partner_content
        ? '12 Mpush1 M10 Lpush2 L8'
        : '12 Mpush1 M10 Lpush1 L7'

    const getPartnerUrl = (partner: any) => {
        if (!partner?.link) return
        const url = Array.isArray(partner.link) ? partner.link[0] : partner.link
        
        return url && url.indexOf('http') === 0 ? url : `https://${url}`
    }

    return (
        <>
            <div className='article-body o-grid-container'>
                <div className='o-grid-row'>
                    <div data-o-grid-colspan={partnerContentClass}>
                        {introduction ? (
                            <div 
                                className='article-aside-box article-summary-box'
                                dangerouslySetInnerHTML={{ __html: introduction }}
                            ></div>
                        ) : null}
                        <section
                            className='body__text'
                            dangerouslySetInnerHTML={{
                                __html: injectComponentsIntoBody(),
                            }}
                        />
                        {partner_content && (
                            <section>
                                <h3 className='article-related-topics__title'>
                                    Find out more about{' '}
                                    {partner_content.map(
                                        (partner, index) =>
                                            partner.name +
                                            (index < partner_content.length - 1
                                                ? ', '
                                                : '')
                                    )}
                                </h3>
                                <div className='logos'>
                                    {partner_content.map((partner, index) => (
                                        <a
                                            key={index}
                                            href={getPartnerUrl(partner)}
                                            target='_blank'
                                        >
                                            <img
                                                key={index}
                                                src={partner.image}
                                                alt={partner.name}
                                            />
                                        </a>
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                    {!partner_content && (
                        <div
                            className='article-ad'
                            data-o-grid-colspan='0 Lpush8 L3'
                        >
                            {mostRead?.length ? (
                                <div className='most-read-desktop'>
                                    <MostReadArticles mostRead={mostRead} />
                                </div>
                            ) : null}
                            <div
                                className='o-ads o-ads--center o-ads--placeholder'
                                data-o-ads-formats='MediumRectangle'
                                data-o-ads-name='mid1'
                                data-o-ads-targeting='pos=mid1'
                                aria-hidden='true'
                            />
                        </div>
                    )}
                </div>
                {!partner_content && (
                    <div className='most-read-mobile'>
                        <MostReadArticles mostRead={mostRead} />
                    </div>
                )}
            </div>
            {genre === 'Rankings and awards' &&
                ranking &&
                ranking.contact &&
                ranking.contact.email && <ArticleRanking ranking={ranking} />}
        </>
    )
}

export default ArticleBody
