/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, FC } from 'react'

import SocialMediaButtonList from './SocialMediaButtonList'
import writeLog from '../../utils/logging'

const fallbackBoundingClientRect = { top: 0, height: 0 }

interface ArticleSocialMediaButtonsProps {
    title: string
    teaser: string
    type?: string
}

const ArticleSocialMediaButtons: FC<ArticleSocialMediaButtonsProps> = (
    props = {
        title: '',
        teaser: '',
        type: '',
    }
) => {
    const linksRef = useRef<any>(null)
    const FIXED_OFFSET = 150
    const [url, setUrl] = useState('')
    const [view, setView] = useState('vertical')
    // eslint-disable-next-line prefer-const
    let [width, setWidth] = useState(0)

    // let width = window.screen.width
    // const [view, setView] = useState(width < 740 ? 'mobile' : 'vertical')
    // const url = window.location.href

    useEffect(() => {
        setUrl(window.location.href)
        setView(window.screen.width < 740 ? 'mobile' : 'vertical')
        setWidth(window.screen.width)
    }, [])

    const determineHeaderOffset = (
        hasImage: any,
        headerImageDom: any,
        gapsBetweenHeaderAndMetadata: any,
        bodyDom: any
    ) => {
        const headerImageDimensions = headerImageDom
            ? headerImageDom.getBoundingClientRect()
            : fallbackBoundingClientRect
        const bodyDomDimensions = bodyDom
            ? bodyDom.getBoundingClientRect()
            : fallbackBoundingClientRect

        return hasImage
            ? headerImageDimensions +
                  gapsBetweenHeaderAndMetadata +
                  bodyDomDimensions.height
            : gapsBetweenHeaderAndMetadata + bodyDomDimensions.height
    }

    const getMargins = (el: any) => {
        const style = window.getComputedStyle(el)
        return (
            parseInt(style.getPropertyValue('margin-top')) +
            parseInt(style.getPropertyValue('margin-bottom'))
        )
    }

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const oShare = require('@financial-times/o-share/browser')
        new oShare(document.querySelector('[data-o-component=o-share]')) // eslint-disable-line
    }, [])

    useEffect(() => {
        let throttled = false
        const delay = 24
        width = window.screen.width
        let headerOffset: any

        const toggleAbsolute = (el: any, height: any) => {
            if (el.style.position !== 'absolute' && view === 'vertical') {
                el.style.position = 'absolute'
                el.style.marginTop = height
                el.style.top = 0
            } else if (view === 'mobile') {
                el.removeAttribute('style')
            }
        }

        const toggleFixed = (el: any, height: any) => {
            if (el.style.position !== 'fixed' && view === 'vertical') {
                el.style.position = 'fixed'
                el.style.top = height + 'px'
                el.style.marginTop = 0
            } else if (view === 'mobile') {
                el.removeAttribute('style')
            }
        }

        const setScrollingLogic = () => {
            const linksDom = linksRef.current
            let bodyDom =
                document.querySelectorAll('.body__text')[width < 980 ? 0 : 1]
            const hasImage =
                document.querySelector('.article-header__image') !== null
            const headerImageDom = hasImage
                ? document.querySelector('.article-header__image-container')
                : null
            const metadataDom = document.querySelector('.article-metadata')
            const headerDom = headerImageDom || metadataDom
            let gapsBetweenHeaderAndMetadata = 0

            if (headerDom && metadataDom) {
                gapsBetweenHeaderAndMetadata = hasImage
                    ? metadataDom.getBoundingClientRect().bottom -
                      headerDom.getBoundingClientRect().bottom +
                      8
                    : metadataDom.getBoundingClientRect().height +
                      getMargins(bodyDom)
            }

            headerOffset = determineHeaderOffset(
                hasImage,
                headerImageDom,
                gapsBetweenHeaderAndMetadata,
                bodyDom
            )

            const originalLinks = linksDom
                ? linksDom.getBoundingClientRect()
                : fallbackBoundingClientRect
            const originalBody = bodyDom
                ? bodyDom.getBoundingClientRect()
                : fallbackBoundingClientRect
            const originalHeader = headerDom
                ? headerDom.getBoundingClientRect()
                : fallbackBoundingClientRect
            const scrolled = window.pageYOffset

            const setLinkState = (
                scrolledAmount: any,
                links: any,
                body: any,
                header: any
            ) => {
                if (
                    scrolledAmount <
                        scrolledAmount + links.top - FIXED_OFFSET &&
                    scrolledAmount < scrolledAmount + header.top - FIXED_OFFSET
                ) {
                    toggleAbsolute(linksDom, '0')
                } else if (
                    body &&
                    scrolledAmount >
                        scrolledAmount + header.top - FIXED_OFFSET &&
                    scrolledAmount <
                        scrolledAmount +
                            body?.top +
                            body?.height -
                            links?.height -
                            FIXED_OFFSET
                ) {
                    toggleFixed(linksDom, FIXED_OFFSET)
                } else if (
                    body &&
                    scrolledAmount >
                        scrolledAmount +
                            body?.top +
                            body?.height -
                            links?.height -
                            FIXED_OFFSET
                ) {
                    toggleAbsolute(linksDom, headerOffset - links.height + 'px')
                } else {
                    toggleAbsolute(linksDom, '0')
                }
            }

            const updateLinks = () => {
                width = window.screen.width
                headerOffset = determineHeaderOffset(
                    hasImage,
                    headerImageDom,
                    gapsBetweenHeaderAndMetadata,
                    bodyDom
                )
                bodyDom =
                    document.querySelectorAll('.body__text')[
                        width < 980 ? 0 : 1
                    ]
                setView(width < 740 ? 'mobile' : 'vertical')
                if (width > 739) {
                    const currentLinks = linksDom.getBoundingClientRect()
                    const currentBody = bodyDom?.getBoundingClientRect()
                    const currentHeader = headerDom?.getBoundingClientRect()
                    const currentScrolled = window.pageYOffset
                    setLinkState(
                        currentScrolled,
                        currentLinks,
                        currentBody,
                        currentHeader
                    )
                } else {
                    cleanup()
                    linksDom.style.position = 'static'
                }
            }

            const throttle = (delay: any, updateLinks: any) => {
                return () => {
                    if (!throttled) {
                        updateLinks()
                    }

                    throttled = true

                    setTimeout(() => {
                        throttled = false
                    }, delay)
                }
            }

            const cleanup = () => {
                if (width > 739) {
                    // FIXME:
                    // document.removeEventListener('scroll', throttle)
                }
                // window.removeEventListener('resize', throttle)
            }

            setLinkState(scrolled, originalLinks, originalBody, originalHeader)

            if (width > 739) {
                document.addEventListener(
                    'scroll',
                    throttle(delay, updateLinks)
                )
            }
            window.addEventListener('resize', throttle(delay, updateLinks))

            return {
                updateLinks,
                cleanup,
            }
        }

        let scrollingLogic = null

        try {
            scrollingLogic = setScrollingLogic()
            scrollingLogic.updateLinks()
        } catch (e) {
            writeLog(e, 'error')
        }

        if (scrollingLogic !== null) {
            return scrollingLogic.cleanup()
        }
    }, [view])

    return (
        <div
            ref={linksRef}
            className={`article-header__social-media article-header__social-media--${view}`}
        >
            <div
                data-o-component='o-share'
                className={`o-share o-share--${view}`}
            >
                <SocialMediaButtonList
                    title={props.title}
                    teaser={props.teaser}
                    url={url}
                />
            </div>
        </div>
    )
}

export default ArticleSocialMediaButtons
