import React from 'react'

import { Text } from './components/Text'
import TitleBulletsColumns from './components/TitleBulletsColumns'
import NormalWithNumbers from './components/NormalWithNumbers'
import Rates from './components/Rates'
import Sizes from './components/Sizes'
import LocalNavigation from './components/LocalNavigation'
import { Normal } from './components/Normal'

export const componentsMap = {
    text: Text,
    'title-bullets': TitleBulletsColumns,
    navigation: LocalNavigation,
    'normal-with-numbers': NormalWithNumbers,
    rates: Rates,
    sizes: Sizes,
    normal: Normal,
}

interface Section {
    type: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items?: any[]
    link?: string
}

export interface MapComponentProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    section: Section
    product: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sections: Section[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
    }
    index: number
    counter: {
        current: Record<string, number>
    }
    setCounter: (sectionType: string) => void
}

export const MapComponent: React.FC<MapComponentProps> = ({
    section,
    product,
    index,
    counter,
    setCounter,
}) => {
    let { type } = section
    const isNormalWithBullets =
        section.type === 'normal' && section.items && section.items.length > 0
    const isWithTestionials =
        section.type === 'testimonial' &&
        product.sections.some(section => section.type === 'who-uses')
    const isTestimonialWithoutWhoUses =
        section.type === 'testimonial' &&
        !product.sections.some(section => section.type === 'who-uses')
    const isEvenStyle = (index: number) => index % 2 === 1
    const isSomeTestimonial = product.sections.some(
        section => section.type === 'testimonial'
    )
    const isRatesOrSizesAfterEven =
        counter.current['normal-with-numbers'] % 2 === 0 &&
        (section.type === 'rates' || section.type === 'sizes')

    if ((type === 'who-uses' && isSomeTestimonial) || isRatesOrSizesAfterEven)
        return <></>

    type = isWithTestionials ? 'who-uses-extended' : type
    type = isNormalWithBullets ? 'normal-with-bullets' : type
    type = isTestimonialWithoutWhoUses ? 'testimonial' : type

    if (!componentsMap[type as keyof typeof componentsMap]) {
        return <div className='missing-component'>{type}</div>
    }

    const Component = componentsMap[type as keyof typeof componentsMap]

    setCounter(type)

    return (
        <div
            id={section.link}
            className={`information-section ${type}`}
            data-trackable-section={`section-${type}`}
        >
            <Component
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                section={section as any}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                items={section.items as any}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                product={product as any}
                isRight={isEvenStyle(index)}
                includeSizesAndRates={
                    counter.current[section.type] % 2 === 0 &&
                    section.type === 'normal-with-numbers'
                }
            />
        </div>
    )
}
