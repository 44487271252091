import React from 'react'

import { MostReadArticle } from '../../types'

interface ArticleMostReadProps {
    mostRead: MostReadArticle[]
}

const ArticleMostRead = ({ mostRead }: ArticleMostReadProps) => {
    const baseClassName = 'most-read-articles'
    if (!mostRead) return null

    return (
        <aside
            className={baseClassName}
            data-trackable-section='most-read-articles'
        >
            <h2 className={`${baseClassName}__title`}>Most read</h2>
            <ul className={`${baseClassName}__list`}>
                {/* eslint-disable @typescript-eslint/no-explicit-any */}
                {mostRead.map((article: any, index: number) => {
                    return (
                        <li
                            key={article.articleId}
                            className={`${baseClassName}__list__item`}
                        >
                            <a
                                className={`${baseClassName}__list__item__link`}
                                href={article.articleLink}
                            >
                                {`${index + 1}. ${article.articleTitle}`}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </aside>
    )
}

export default ArticleMostRead
