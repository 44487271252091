import React from 'react'

const NewsletterBox = () => (
    <div className='article-newsletter-box'>
        <p>
            <strong>
                Do you want more FDI stories delivered directly to your inbox?{' '}
                <a
                    href='https://www.fdiintelligence.com/newsletter-signup'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Subscribe to our newsletters
                </a>
                .
            </strong>
        </p>
    </div>
)

export default NewsletterBox
