import { getState } from '../utils/helpers'
import oHeaderServices from '@financial-times/o-header-services/browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import GtmSnippet from '../components/gtm-snippet/GtmSnippet'
import VideoBlock from '../components/homepage-video-block/HomepageVideoBlock'
import Header from '../components/header/Header'
import CommercialLayout from '../components/commercial/layout'
import ODate from '../components/date/Date'
import ArticleSocialMediaButtons from '../components/article-social-media-buttons/ArticleSocialMediaButtons'
import NewsletterSignup from '../components/newsletter-signup/NewsletterSignupComponent'
import ArticleHeaderImageFull from '../components/article-header-image/ArticleHeaderImageFull'
import ArticleBody from '../components/article-body/ArticleBody'
import SpecialReport from '../components/special-report/SpecialReport'
import EventHub from '../components/event-hub/EventHub'
import ArticleComponent from '../components/article/Article'
import { getArticleType, getColour, getGenreAndBrand } from '../utils/article'
import AdvertisingOpportunitiesComponent from '../components/advertising-opportunities'
import { FeatureFlagsProvider } from '../components/provider/FeatureFlagsProvider'

const state = getState()

// /**
//  * Renders a specific part of the UI by element id with a React component.
//  * @param {string} elementId - The id of the element to render.
//  * @param {React.ComponentType} Component - The React component to render.
//  * @param {object} props - The props to pass to the component.
//  */
const renderComponent = (
    elementId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: React.ComponentType<any>,
    props: object = {}
): void => {
    const container = document.getElementById(elementId)
    if (container) {
        const root = createRoot(container)
        root.render(<Component {...props} />)
    }
}

const renderComponentWithDifferentIds = (
    idToProps: Record<string, object>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: React.ComponentType<any>
) => {
    Object.entries(idToProps).forEach(([id, props]) => {
        renderComponent(id, Component, props)
    })
}

window.addEventListener('DOMContentLoaded', () => {
    oHeaderServices.init()
    renderComponent('gtm-snippet', GtmSnippet)

    if (state.videos && state.environment) {
        renderComponent('video-block', VideoBlock, {
            titleLink: '/videos',
            videos: state.videos,
            playerUrl: state.environment.vimeoPlayerUrl,
        })
    }

    renderComponent('header', () => (
        <FeatureFlagsProvider initialFlags={state.featureFlags}>
            <Header />
        </FeatureFlagsProvider>
    ))
    renderComponent('commercial', CommercialLayout)

    if (state.article) {
        const [genre, brand] = getGenreAndBrand(state.article)
        const articleType = getArticleType(state.article)
        const colour = getColour(articleType)

        renderComponent('article-component', ArticleComponent, {
            article: state.article,
            flags: state.flags,
            articleType,
            colour,
            genre,
            brand,
            mostRead: state.mostRead,
            environment: state.environment,
        })
    }
    renderComponent(
        'advertising-opportunities',
        AdvertisingOpportunitiesComponent,
        {
            product: state.page,
        }
    )

    if (state.report) {
        renderComponent('special-report', SpecialReport, {
            report: state.report,
        })
    }

    if (state.event) {
        renderComponent('event-hub', EventHub, {
            event: state.event,
            url: state.url,
        })
    }

    if (state.article) {
        renderComponentWithDifferentIds(
            {
                'article-metadata-date': {
                    id: 'article-metadata-date',
                    dateTime: state.article?.publication_date,
                    format: 'MMMM d, yyyy',
                    className: 'article-metadata__date',
                    isClientSide: true,
                },
                'article-ranking-closing-date': {
                    id: 'article-ranking-closing-date',
                    dateTime: state.article?.ranking?.closing_date,
                    isClientSide: true,
                },
                'article-ranking-publication-date': {
                    id: 'article-ranking-publication-date',
                    dateTime: state.article?.ranking?.publish_date,
                    format: 'MMMM yyyy',
                    isClientSide: true,
                },
                'report-header__publication-date': {
                    id: 'report-header__publication-date',
                    dateTime:
                        state.report?.publish_date ||
                        state.article?.publication_date ||
                        '',
                    isClientSide: true,
                },
            },
            ODate
        )

        renderComponent(
            'article-social-media-buttons',
            ArticleSocialMediaButtons,
            {
                title: state.article.title,
                teaser: state.article.teaser,
            }
        )
        renderComponent('newsletter-signup', NewsletterSignup, {
            url: state.url,
            referrer: state.referrer,
            reCaptchaKey: state.environment.reCaptchaKey,
            name: state.name,
            apolloServerUrl: state.environment.apolloServerUrl,
        })
        renderComponent('article-header-image-full', ArticleHeaderImageFull, {
            image: state.article.image,
            caption: state.article.teaser_image_caption,
        })
        renderComponent('article-body', ArticleBody, {
            flags: state.flags,
            article: state.article,
            mostRead: state.mostRead,
            colour: state.colour,
            genre: state.genre,
            environment: state.environment,
        })
    }
})
