/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import { cleanHtmlTags } from '../utils/helpers'

interface SocialMediaButtonListProps {
    title: string
    teaser: string
    url: string
}

const SocialMediaButtonList: FC<SocialMediaButtonListProps> = (
    { url, title, teaser } = {
        title: '',
        teaser: '',
        url: '',
    }
) => {
    teaser = cleanHtmlTags(teaser)

    const buttons: any = [
        {
            name: 'twitter',
            href: `https://twitter.com/intent/tweet?url=${url}&amp;text=${title}&amp;via=fDiIntelligence`,
            target: '_self',
        },
        {
            name: 'facebook',
            href: `http://www.facebook.com/sharer.php?u=${url}`,
            target: '_self',
        },
        {
            name: 'linkedin',
            href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${teaser}&source=fDi%20Intelligence`,
            target: '_self',
        },
        // {
        //   name: 'whatsapp',
        //   href: `whatsapp://send?text=${props.title}%20-%20${props.url}`,
        //   target: '_blank'
        // },
        // {
        //   name: 'share',
        //   href: props.url,
        //   target: '_self',
        //   withText: true
        // }
    ]
    return (
        <ul className='social-sharing-buttons'>
            {buttons.map(({ href, name, target, withText }: any) => {
                return (
                    <li
                        className={`o-share__action ${withText ? 'o-share__action--with-text' : ''}`}
                        key={name}
                    >
                        <a
                            className={`o-share__icon o-share__icon--${name} ${withText ? 'o-share__icon--with-text' : ''}`}
                            target={target}
                            href={href}
                            data-trackable={name}
                            rel='noopener'
                        >
                            <span
                                className={`o-share__text ${withText ? 'o-share__text--with-text' : ''}`}
                            >
                                {name}
                            </span>
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}

export default SocialMediaButtonList
