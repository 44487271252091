import React from 'react'

const CurrencyContext = React.createContext({
    currency: '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrency: (_: string) => {},
    currencies: [''],
})

export const CurrencyProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const [currency, setCurrency] = React.useState<string>('GBP')
    const currencies: string[] = ['USD', 'EUR', 'GBP']

    return (
        <CurrencyContext.Provider value={{ currency, setCurrency, currencies }}>
            {children}
        </CurrencyContext.Provider>
    )
}

export const useCurrency = () => {
    const context = React.useContext(CurrencyContext)
    if (context === undefined) {
        throw new Error('useCurrency must be used within a CurrencyProvider')
    }
    return context
}
