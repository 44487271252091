import React from 'react'

export interface SizesProps {
    section: {
        title: string
        image: string
        items: {
            title: string
            image: string
            span: number
            color: string
            [key: string]: string | number
        }[]
    }
}

export const Sizes: React.FC<SizesProps> = ({ section }) => {
    return (
        <div className='sizes'>
            <h2 className='sizes-title'>{section.title}</h2>
            {section.image && <img src={section.image} alt={section.title} />}
            <ul className='sizes-list'>
                {section.items.map((item, index) => (
                    <li
                        key={index}
                        style={{ gridColumn: `span ${item.span || 1}` }}
                    >
                        {item.image && (
                            <img src={item.image} alt={item.title} />
                        )}
                        <p
                            className='size-title'
                            style={{ color: item.color || '#000' }}
                        >
                            {item.title}
                        </p>
                        {Object.keys(item).map((key, index) => {
                            if (
                                key !== 'title' &&
                                key !== 'image' &&
                                key !== 'color' &&
                                key !== 'span'
                            ) {
                                return (
                                    <p key={index}>
                                        {key}: {item[key]}
                                    </p>
                                )
                            }
                        })}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Sizes
