import React, { FC, useEffect } from 'react'

export interface MPUProps {
    initAds?: boolean
    type: string
}

const MPU: FC<MPUProps> = (
    props = {
        initAds: false,
        type: '',
    }
) => {
    // This was necessary to get the MPU to appear on the page because of dynamically loading each header component
    useEffect(() => {
        if (props.initAds) {
            window.oAds.initAll()
        }
    }, [props.initAds])

    return (
        <div
            className='o-ads o-ads--center o-ads--placeholder'
            aria-hidden='true'
            data-o-ads-name={props.type}
        />
    )
}

export default MPU
