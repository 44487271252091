/**
 * Function that gets only the names from an authors array
 * and returns an array of names
 * @param {Author[]}
 * @returns {string[]|null} Author names
 */

interface Author {
    name: string
}

export const getAuthorNamesArray = (author: Author[]) => {
    if (!author) return null
    return author.map(author => author.name)
}

/**
 * Function that gets only the names from an authors array
 * and returns a string
 * @param {Author[]}
 * @returns {String}  Author names
 */

export const getAuthorNames = (author: Author[]) => {
    if (!author) return ''
    const authorNames = getAuthorNamesArray(author)
    return authorNames ? authorNames.join(', ') : ''
}
