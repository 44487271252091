import { useState, useEffect } from 'react'

export interface MenuItemActiveProps {
    items: { link: string }[]
}

export const useMenuItemActive = ({ items }: MenuItemActiveProps) => {
    const [activeItem, setActiveItem] = useState<string | null>(null)

    useEffect(() => {
        const handleScrollAndResize = () => {
            let currentActiveItem: string | null = activeItem
            const activationPoint = window.innerHeight / 4
            let foundActive = false

            items.forEach((item, index) => {
                const element = document.getElementById(item.link)
                if (element) {
                    const rect = element.getBoundingClientRect()
                    const elementTop = rect.top + window.pageYOffset
                    const elementBottom = elementTop + rect.height

                    if (
                        window.pageYOffset + activationPoint >= elementTop &&
                        window.pageYOffset + activationPoint <= elementBottom
                    ) {
                        currentActiveItem = item.link
                        foundActive = true
                    } else if (foundActive && currentActiveItem === item.link) {
                        if (index + 1 < items.length) {
                            const nextElement = document.getElementById(
                                items[index + 1].link
                            )
                            if (nextElement) {
                                const nextRect =
                                    nextElement.getBoundingClientRect()
                                const nextTop =
                                    nextRect.top + window.pageYOffset
                                if (
                                    window.pageYOffset + activationPoint <
                                    nextTop
                                ) {
                                    currentActiveItem = item.link
                                }
                            }
                        }
                    }
                }
            })

            setActiveItem(currentActiveItem)
        }

        window.addEventListener('scroll', handleScrollAndResize)
        window.addEventListener('resize', handleScrollAndResize)
        handleScrollAndResize()

        return () => {
            window.removeEventListener('scroll', handleScrollAndResize)
            window.removeEventListener('resize', handleScrollAndResize)
        }
    }, [items, activeItem])

    return activeItem
}

export default useMenuItemActive
