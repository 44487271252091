import React, { FC } from 'react'

export interface TagProps {
    type: string
    label?: string
    colspan?: number | string
}

const Tag: FC<TagProps> = props => {
    const label = props.type !== 'advertorial' ? props.type : 'Partner Content'

    return (
        <div className='tag-container'>
            <span
                className={`tag tag--${props.type}`}
                data-o-grid-colspan={props.colspan || null}
            >
                {props.label || label}
            </span>
        </div>
    )
}

export default Tag
