/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import colours from '../../utils/colours'

import superTextConfig from './config/superText'
import writeLog from '../../utils/logging'

interface ArticleTitleProps {
    white?: boolean
    superText?: string | null
    title: string
    isPartnerContent?: boolean
    hideHeaderImage?: boolean
}

const ArticleTitle: FC<ArticleTitleProps> = ({
    white = false,
    superText = '',
    isPartnerContent = false,
    title,
    hideHeaderImage = false,
}) => {
    let titleColour

    try {
        titleColour = colours.find(colour => {
            return colour.name === 'fdi-off-black'
        })?.hex
    } catch (e) {
        writeLog(e, 'error')
    }

    const color = white ? 'white' : titleColour
    const superTextClassName = superText
        ? `article-header__title--supertext article-header__title--${superText}`
        : ''
    const superTextValue = superTextConfig[
        superText as keyof typeof superTextConfig
    ]
        ? superTextConfig[superText as keyof typeof superTextConfig]
        : null

    const colspanClasses = isPartnerContent
        ? '12 Mpush1 Lpush2 M10 L8'
        : '12 Mpush1 M10 L7'

    return (
        <div className='article-header__title-container'>
            <div
                className={`o-grid-row ${isPartnerContent && 'partner-title'}`}
                style={
                    {
                        '--hide-header-margin': hideHeaderImage
                            ? '-1rem'
                            : '-8rem',
                    } as any
                }
            >
                {superText && (
                    <h3
                        className='article-header__title-supertext'
                        data-o-grid-colspan={colspanClasses}
                        style={
                            {
                                '--supertext-color': color,
                            } as any
                        }
                    >
                        {superTextValue}
                    </h3>
                )}
                <h1
                    className={`article-header__title ${superTextClassName}`}
                    data-o-grid-colspan={colspanClasses}
                    style={
                        {
                            '--title-color': color,
                        } as any
                    }
                >
                    {title}
                </h1>
            </div>
        </div>
    )
}

export default ArticleTitle
