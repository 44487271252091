import React, { useState } from 'react'

import { fireEvent } from '../../tracking/Tracking'

const SearchBox = () => {
    const [searchValue, setSearchValue] = useState('')
    const [hasFocus, setHasFocus] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (event: any) => {
        setSearchValue(event.target.value)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFocus = (event: any) => {
        event.preventDefault()
        setHasFocus(true)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleBlur = (event: any) => {
        event.preventDefault()
        setHasFocus(false)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (event: any) => {
        event.preventDefault()
        fireEvent(
            {
                category: 'cta',
                action: 'search',
                value: 'submit',
                'data-trackable': searchValue,
            },
            true
        )

        const encodedSearchValue = encodeURIComponent(searchValue)
        window.location.href = `/search?query=${encodedSearchValue}`
    }

    return (
        <div className='search-box' data-o-grid-colspan='4 Lpush3'>
            <form
                onSubmit={handleSubmit}
                className='search-box__form o-forms'
                data-o-component='o-forms'
            >
                <div
                    className={
                        'o-forms__affix-wrapper ' +
                        (hasFocus ? 'has-focus' : '')
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    <input
                        type='text'
                        name='query'
                        placeholder='Search for locations, sectors, topics and more'
                        value={searchValue}
                        onChange={handleChange}
                        data-trackable='Search Box'
                    />
                    <div className='o-forms__suffix'>
                        <input
                            aria-label='Search for locations, sectors, topics and more'
                            type='submit'
                            className='o-buttons o-buttons--secondary o-buttons--big'
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SearchBox
