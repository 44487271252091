import React from 'react'

export interface HeadlineProps {
    children: React.ReactNode
}

const Headline = ({ children }: HeadlineProps) => {
    return (
        <>
            <h2>{children}</h2>
        </>
    )
}

export default Headline
