/* eslint-disable camelcase */
import React, { FC } from 'react'

import ArticleAuthor from '../../../article-author/ArticleAuthor'
import ArticleBanner from '../../../article-banner/ArticleBanner'
import ArticleHeaderImage from '../../../article-header-image/ArticleHeaderImage'
import ArticleMetadata from '../../../article-metadata/ArticleMetadata'
import ArticleSocialMediaButtons from '../../../article-social-media-buttons/ArticleSocialMediaButtons'
import ArticleTeaser from '../../../article-teaser/ArticleTeaser'
import ArticleTitle from '../../../article-title/ArticleTitle'
import ArticleSponsors from '../../../sponsors/Sponsors'
import { getAuthorNames } from '../../../utils/article/authorNames'
import { Article } from '../../../../types'
import Hydration from '../../../Hydration'

interface ArticleHeaderRankingsProps {
    type?: string
    article: Article
    colour: string
}

const ArticleHeaderRankings: FC<ArticleHeaderRankingsProps> = props => {
    const {
        title,
        image,
        teaser,
        author,
        advertorial_sponsor,
        publication_date,
        byline,
    } = props.article

    return (
        <div className='article-header article-header-rankings'>
            <ArticleBanner article={props.article} colour={props.colour}>
                <ArticleTitle
                    title={title}
                    white
                    superText={props.type || null}
                />
                <ArticleTeaser teaser={teaser} white />
                <ArticleAuthor
                    author={byline ? byline : getAuthorNames(author)}
                    isWhite
                />
                <ArticleSponsors sponsors={advertorial_sponsor} />
            </ArticleBanner>
            <div className='article__social-media-container'>
                <div className='o-grid-container'>
                    <ArticleHeaderImage image={image} />
                    <Hydration id='article-social-media-buttons'>
                        <ArticleSocialMediaButtons
                            title={title}
                            teaser={teaser}
                        />
                    </Hydration>
                </div>
                <ArticleMetadata date={publication_date} image={image} />
            </div>
        </div>
    )
}

export default ArticleHeaderRankings
