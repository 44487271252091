import React from 'react'

import { FeaturedTileProps, FeaturedTile } from './FeaturedTile'
import { Rates } from './Rates'
import { Sizes } from './Sizes'

export interface Section {
    image: string
    type: string
    title: string
    items: {
        title: string
        image: string
        span: number
        color: string
    }[]
    noColumns: boolean
    paragraph: string
    numbers: { count: string; title: string }[]
    features: FeaturedTileProps[]
}

export interface Product {
    sections: Section[]
}

export interface NormalWithNumbersProps {
    section: Section
    product: Product
    isRight: boolean
    includeSizesAndRates: boolean
}

export const NormalWithNumbers: React.FC<NormalWithNumbersProps> = ({
    section,
    product,
    isRight,
    includeSizesAndRates = false,
}) => {
    let sizesAndRates: Section[] = []

    if (includeSizesAndRates) {
        const sectionIndex = product.sections.findIndex(
            s =>
                s.type === section.type &&
                s.type === 'normal-with-numbers' &&
                s.title === section.title
        )
        const nextTwoSections = product.sections.slice(
            sectionIndex + 1,
            sectionIndex + 3
        )
        sizesAndRates = nextTwoSections.filter(
            s => s.type === 'sizes' || s.type === 'rates'
        )
    }

    const splitText = (text = '') => {
        const split = text.split('')
        return split.map((char, index) => {
            if (isNaN(Number(char)) && char !== ',') {
                return (
                    <small style={{ fontSize: '2rem' }} key={index}>
                        {char}
                    </small>
                )
            }
            return char
        })
    }

    return (
        <div className={`normal-with-numbers ${isRight ? 'right' : ''}`}>
            <div className='text'>
                {section.title && <h2>{section.title}</h2>}
                {section.paragraph && <p>{section.paragraph}</p>}
                <div className='counters'>
                    {section.numbers.map((item, index) => (
                        <div key={index} className='counter'>
                            {item.count && <h3>{splitText(item.count)}</h3>}
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
                <div className='section-with-features'>
                    <div
                        className='features'
                        style={{
                            flexDirection: includeSizesAndRates
                                ? 'column'
                                : 'row',
                            ...(includeSizesAndRates && {
                                width: '30%',
                                alignSelf: 'flex-start',
                            }),
                        }}
                    >
                        {section.features.map((feature, index) => (
                            <FeaturedTile key={index} {...feature} hasBorder />
                        ))}
                    </div>
                    {includeSizesAndRates && (
                        <div className='other-features'>
                            {sizesAndRates &&
                                sizesAndRates.map((section, index) =>
                                    section.type === 'sizes' ? (
                                        <Sizes key={index} section={section} />
                                    ) : (
                                        <Rates key={index} section={section} />
                                    )
                                )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NormalWithNumbers
