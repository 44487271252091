import React, { FC } from 'react'

import Iframe from '../iframe/Iframe'
import { ACAST_BASE_URL } from '../../assets/constants'

interface ACastIframeProps {
    podcastId: string
}

const ACastIframe: FC<ACastIframeProps> = props => {
    return <Iframe link={`${ACAST_BASE_URL}${props.podcastId}`} />
}

export default ACastIframe
