import React, { FC } from 'react'
interface ArticleVideo {
    accountId?: string
    playerId?: string
    playerUrl?: string
    videoId?: string
    title?: string
    vimeoPlayerUrl?: string
}

const ArticleVideo: FC<ArticleVideo> = (
    props = {
        accountId: '',
        playerId: '',
        playerUrl: '',
        videoId: '',
        title: '',
        vimeoPlayerUrl: '',
    }
) => {
    return (
        <>
            <div
                style={{
                    padding: '48.45% 0px 0px',
                    marginBottom: '2rem',
                    position: 'relative',
                }}
            >
                <iframe
                    src={`${props.vimeoPlayerUrl}${props.videoId}`}
                    frameBorder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: 'calc(100% - 20px)',
                    }}
                    title={props.title || ''}
                />
            </div>
        </>
    )
}

export default ArticleVideo
