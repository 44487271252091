import React, { FC, useEffect } from 'react'
import ButtonLink from '../button-link/ButtonLink'

interface EventsHubHeaderProps {
    title?: string
    eventTeaser?: string
    start_date?: string
    end_date?: string
    location?: string
    image?: { image: string }
    description?: string
    nominatedTag?: string
    genericTag?: string
    hashtag?: string
    type?: string
    find_out_more?: { url: string }
    register_now?: { url: string }
    url?: string
}

const EventsHubHeader: FC<EventsHubHeaderProps> = ({
    title = '',
    eventTeaser = '',
    location = '',
    image = null,
    description = '',
    hashtag = '',
    type = '',
    find_out_more = { url: '' },
    register_now = { url: '' },
    start_date = '',
    end_date = '',
    url = '',
}) => {
    const socialButtons = [
        {
            name: 'twitter',
            href: `https://twitter.com/intent/tweet?url=${url}&amp;text=${title}&amp;via=fDiIntelligence`,
            target: '_self',
        },
        {
            name: 'linkedin',
            href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${eventTeaser}&source=fDi%20Intelligence`,
            target: '_self',
        },
    ]

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const oShare = require('@financial-times/o-share/browser')
        new oShare(document.querySelector('[data-o-component=o-share]')) // eslint-disable-line
    }, [])

    return (
        <div className='events-hub-header'>
            <div className='events-hub-header__row o-grid-row'>
                <div
                    className='events-hub-header__column'
                    data-o-grid-colspan='12 Mpush0 M6'
                >
                    <div className='events-hub-header__headings'>
                        <h3 className='events-hub-header__supertext'>{type}</h3>
                        <h1 className='events-hub-header__title'>{title}</h1>
                        <p className='events-hub-header__teaser'>
                            {eventTeaser}
                        </p>
                    </div>
                    <div className='events-hub-header__date-location'>
                        <p className='events-hub-header__date'>
                            {formatEventDate(start_date, end_date)}
                        </p>
                        <p className='events-hub-header__location'>
                            {location}
                        </p>
                    </div>
                    <div className='events-hub-header__media events-hub-header__media--mobile'>
                        <img
                            className='events-hub-header__image events-hub-header__image--mobile'
                            src={image?.image}
                        />
                        <p className='events-hub-header__description events-hub-header__description--mobile'>
                            {description}
                        </p>
                    </div>
                    <div className='events-hub-header__cta'>
                        {find_out_more &&
                            find_out_more.url &&
                            find_out_more.url !== 'false' && (
                                <ButtonLink
                                    title='Find out more'
                                    link={find_out_more.url}
                                    className='events-hub-header__btn'
                                    dontTrack
                                >
                                    Find out more
                                </ButtonLink>
                            )}
                        {register_now &&
                            register_now.url &&
                            register_now.url !== 'false' && (
                                <ButtonLink
                                    title='Register'
                                    link={register_now.url}
                                    className='events-hub-header__btn'
                                    dontTrack
                                >
                                    Register now
                                </ButtonLink>
                            )}
                    </div>
                    <div className='events-hub-header__links-socials-container'>
                        <div className='events-hub-header__socials'>
                            <div
                                data-o-component='o-share'
                                className='o-share o-share--horizontal o-share--inverse'
                            >
                                <ul>
                                    {socialButtons.map(button => {
                                        return (
                                            <li
                                                className='o-share__action'
                                                key={button.name}
                                            >
                                                <a
                                                    className={`o-share__icon o-share__icon--${button.name}`}
                                                    target={button.target}
                                                    href={button.href}
                                                    data-trackable={button.name}
                                                    rel='noopener'
                                                >
                                                    <span className='o-share__text'>
                                                        {button.name}
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {hashtag && (
                                <p className='events-hub-header__hashtag'>
                                    #{hashtag}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className='events-hub-header__media events-hub-header__media--desktop'
                    data-o-grid-colspan='0 M6'
                >
                    <img
                        className='events-hub-header__image events-hub-header__image--desktop'
                        src={image?.image}
                    />
                    <p className='events-hub-header__description events-hub-header__description--desktop'>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}

function formatEventDate(start: string, end: string) {
    const defaultDate = new Date('1970-01-01T00:00:00.000Z')
    const startDate = new Date(start)
    const endDate = new Date(end)

    const formatter = new Intl.DateTimeFormat('en', { month: 'short' })
    const startMonth = formatter.format(startDate)

    // endDate may not be set or the event could be a single day
    if (
        endDate.getTime() === defaultDate.getTime() ||
        endDate.getTime() === startDate.getTime()
    ) {
        return `${startMonth} ${startDate.getDate()}, ${startDate.getFullYear()}`
    }

    const endMonth = formatter.format(endDate)
    return `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`
}

export default EventsHubHeader
