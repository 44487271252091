import React from 'react'

import { __w } from '../../utils/helpers'

export interface FeaturedTileProps {
    icon: string
    title: string
    content: string
    hasBorder?: boolean
}

export const FeaturedTile: React.FC<FeaturedTileProps> = ({
    icon,
    title,
    content,
}) => {
    return (
        <div className='featured-tile'>
            <div className='featured-tile-icon'>
                <img
                    src={`https://static.fdiintelligence.com/assets/marketing/products-and-services/icons/${icon}.svg`}
                />
            </div>
            <h2 className='featured-tile-title'>{__w(title)}</h2>
            <p className='featured-tile-content'>{__w(content)}</p>
        </div>
    )
}

export default FeaturedTile
