import React, { FC } from 'react'

interface SpecialReportBoxProps {
    uuid: string
    url: string
    headline: string
}

const SpecialReportBox: FC<SpecialReportBoxProps> = ({ url, headline }) => (
    <div className='special-report-box'>
        <p>
            <u>This article is part of the Special Report:</u>
            <br />
            <strong>{headline}</strong>
        </p>
        <p>
            <a href={url} target='_blank' rel='noopener noreferrer'>
                Read more articles from the report
            </a>
        </p>
    </div>
)

export default SpecialReportBox
