const colours = [
    {
        name: 'brand-blue',
        hex: '#1173C1',
    },
    {
        name: 'brand-off-white',
        hex: '#fdfdfd',
    },
    {
        name: 'fdi-off-black',
        hex: '#222',
    },
    {
        name: 'fdi-divider-line-grey',
        hex: '#bababa',
    },
    {
        name: 'fdi-ads-background-grey',
        hex: '#ececec',
    },
    {
        name: 'fdi-page-background-grey',
        hex: '#f5f5f5',
    },
    {
        name: 'fdi-focus-blue',
        hex: '#1aecff',
    },
    {
        name: 'fdi-nav-focus-blue',
        hex: '#2971bd',
    },
    {
        name: 'fdi-nav-current-focus-blue',
        hex: '#1a3967',
    },
    {
        name: 'fdi-nav-current-focus-text-blue',
        hex: '#8cbee8',
    },
    {
        name: 'fdi-drop-down-grey',
        hex: '#d6d6d2',
    },
    {
        name: 'fdi-drop-down-grey-hover',
        hex: '#c3c3c3',
    },
    {
        name: 'fdi-benchmark',
        hex: '#007262',
    },
    {
        name: 'fdi-markets',
        hex: '#c41a1b',
    },
    {
        name: 'fdi-gis-planning',
        hex: '#1db3ff',
    },
    {
        name: 'fdi-card-article-background-hover',
        hex: 'rgba(236,236,236,0.75)',
    },
    {
        name: 'fdi-button-background-hover',
        hex: '#deee8ef',
    },
    {
        name: 'fdi-editorial',
        hex: '#004539',
    },
    {
        name: 'fdi-editorial-light',
        hex: '#008284',
    },
    {
        name: 'fdi-advertorial-background',
        hex: '#222e39',
    },
    {
        name: 'fdi-advertorial-background-hover',
        hex: '#212c36',
    },
    {
        name: 'fdi-advertorial-light',
        hex: '#455563',
    },
    {
        name: 'fdi-advertorial-title-hover',
        hex: '#88bee8',
    },
    {
        name: 'fdi-locations-americas',
        hex: '#62081d',
    },
    {
        name: 'fdi-locations-americas-light',
        hex: '#8f0928',
    },
    {
        name: 'fdi-locations-asia-pacific',
        hex: '#410a3a',
    },
    {
        name: 'fdi-locations-europe',
        hex: '#002641',
    },
    {
        name: 'fdi-locations-europe-light',
        hex: '#00477a',
    },
    {
        name: 'fdi-locations-middle-east',
        hex: '#43240c',
    },
    {
        name: 'fdi-locations-middle-east-light',
        hex: '#844413',
    },
]

export default colours
