import React from 'react'

import { useMenuItemActive } from '../../hooks/useMenuItemActive'
import { useStickyNav } from '../../hooks/useStickyNav'

export interface SectionItem {
    link: string
    title: string
}

export interface LocalNavigationProps {
    items: SectionItem[]
    section: { items: SectionItem[] }
}

export const LocalNavigation: React.FC<LocalNavigationProps> = ({
    items,
    section,
}) => {
    const navRef = React.useRef(null)
    const menuRef = React.useRef(null)

    useStickyNav(navRef)

    items = items || section.items || []
    const activeItem = useMenuItemActive({ items })

    if (!items.length) return null

    const handleMenuClick = () => {
        if (menuRef.current) {
            ;(menuRef.current as HTMLElement).classList.toggle('open')
        }
    }

    return (
        <div
            ref={navRef}
            data-trackable-section='local-nav'
            className='local-navigation'
        >
            <ul ref={menuRef}>
                <li className='menu-trigger' onClick={handleMenuClick}>
                    Select your focus need
                    <svg
                        className='icon'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                    >
                        <path
                            fill='white'
                            d='M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'
                        />
                    </svg>
                </li>
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={activeItem === item.link ? 'active' : ''}
                    >
                        <a
                            href={`#${item.link}`}
                            data-trackable={`local-nav-item-${item.link}`}
                            onClick={handleMenuClick}
                        >
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default LocalNavigation
