import React from 'react'

import truncate from 'truncate-html'

export interface TeaserProps {
    className?: string
    content: string
    trimLength: number
}

const Teaser = (props: TeaserProps) => {
    const getTrimText = () => {
        if (!props.content) {
            return ''
        }

        return truncate(props.content, props.trimLength, {
            reserveLastWord: true,
            excludes: 'img',
        })
    }

    const text = getTrimText()

    return (
        <>
            {text.length > 0 && (
                <div className={props.className}>
                    <div
                        className='teaser-paragraph'
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
            )}
        </>
    )
}

export default Teaser
