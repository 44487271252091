import React from 'react'

import ArticleListItem from '../article-list-item/ArticleListItem'
import MPUContainer from '../mpu/mpu-container/MPUContainer'
import SectionTitle from '../section-title/SectionTitle'
import { ArticleListItemProps } from '../article-list-item/ArticleListItem.props'

export interface ArticleListProps {
    title?: string
    articles: ArticleListItemProps[]
    withTopSeparator?: boolean
}

const ArticleList: React.FC<ArticleListProps> = ({
    title = '',
    articles,
    withTopSeparator = true,
}) => {
    return (
        <div className='article-list'>
            <div className='o-grid-container'>
                {title && (
                    <div className='o-grid-row'>
                        <SectionTitle titleContent={title} />
                    </div>
                )}
                <div className='o-grid-row'>
                    <div data-o-grid-colspan='12 L8'>
                        {articles.slice(0, 5).map((article, index) => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                                withTopSeparator={
                                    withTopSeparator && index !== 0
                                }
                            />
                        ))}
                        <div className='bottom-ad'>
                            <div
                                className='o-ads o-ads--center o-ads--placeholder'
                                data-o-ads-name='bottom'
                                data-o-ads-targeting='pos=bottom'
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                    <div data-o-grid-colspan='12 L4'>
                        <MPUContainer type='mid' />
                    </div>
                    <div data-o-grid-colspan='12 L8'>
                        {articles.slice(5).map(article => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                            />
                        ))}
                    </div>
                    <div data-o-grid-colspan='12 L4'>
                        <div className='ad-container'>
                            <MPUContainer type='mid1' className='mpu-bottom' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleList
