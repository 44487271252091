import React, { FC } from 'react'

import { SponsorType } from '../../types'

interface SponsorsProps {
    sponsors?: Array<SponsorType> | null
    specialReport?: {
        name: string
        link: string
    } | null
    colSpan?: string
}

const Sponsors: FC<SponsorsProps> = ({
    sponsors = [],
    specialReport = null,
    colSpan,
}) => {
    if (!sponsors || sponsors.length === 0) {
        return null
    }

    const gridColspan = colSpan || '12 Mpush1 M10 L11'

    const textContainerClassName = [
        'sponsors__text-container',
        ...(sponsors.length === 1
            ? ['sponsors__text-container--single-sponsor']
            : []),
    ].join(' ')

    const url = (s: SponsorType) =>
        s.link && s.link.indexOf('http') === 0 ? s.link : `https://${s.link}`

    return (
        <>
            <div className='sponsors o-grid-row'>
                <div
                    className={textContainerClassName}
                    data-o-grid-colspan={gridColspan}
                >
                    <span className='sponsors__text'>
                        {specialReport ? (
                            <>
                                This article is part of{' '}
                                <a
                                    className='sponsors__bold-text'
                                    href={specialReport.link}
                                >
                                    {specialReport.name}
                                </a>
                                , a special report supported by
                            </>
                        ) : (
                            <>Supported by</>
                        )}
                    </span>
                    {sponsors.length === 1 && (
                        <div className='sponsors__sponsor sponsors__sponsor--single-sponsor'>
                            <a
                                href={url(sponsors[0])}
                                target='_blank'
                                className='sponsors__bold-name'
                                rel='noopener noreferrer'
                            >
                                {sponsors[0].name}
                            </a>
                        </div>
                    )}
                </div>
                {sponsors.length > 1 && (
                    <div data-o-grid-colspan={gridColspan}>
                        <div className='sponsors__multiple'>
                            {sponsors.map(sponsor => (
                                <div
                                    key={sponsor.id}
                                    className='sponsors__sponsor'
                                >
                                    <a
                                        href={url(sponsor)}
                                        className='sponsors__bold-name'
                                    >
                                        {sponsor.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Sponsors
