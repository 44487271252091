import React from 'react'

import { useCurrency } from '../../context/CurrencyContext'

export interface RatesProps {
    section: {
        title: string
        image: string
        noColumns: boolean
        items: {
            title: string
            priceUSD?: number
            price?: number
            priceEUR?: number
        }[]
    }
}

export const Rates: React.FC<RatesProps> = ({ section }) => {
    const { currencies, currency, setCurrency } = useCurrency()
    const [fadeClass, setFadeClass] = React.useState('')
    const getPrice = (item: {
        priceUSD?: number
        price?: number
        priceEUR?: number
    }) =>
        currency === 'USD'
            ? item.priceUSD
            : currency === 'GBP'
              ? item.price
              : item.priceEUR

    const changeCurrency = (c: string) => {
        setFadeClass('fade-out')
        setTimeout(() => {
            setCurrency(c)
            setFadeClass('fade-in')
        }, 250)
        setTimeout(() => {
            setFadeClass('')
        }, 500)
    }

    return (
        <div className='rates-columns'>
            {section.image && (
                <div className='image'>
                    <img src={section.image} alt={section.title} />
                </div>
            )}
            <div className='text'>
                <h2>
                    {section.title}
                    <div className='currency-switcher'>
                        {currencies.map((c, index) => (
                            <button
                                key={index}
                                onClick={() => changeCurrency(c)}
                                className={currency === c ? 'active' : ''}
                                type='button'
                            >
                                {c}
                            </button>
                        ))}
                    </div>
                </h2>
                <div className='rates-table'>
                    {section.items.map((item, index) => (
                        <div key={index} className='rate'>
                            {section.noColumns ? (
                                <h3>
                                    {item.title}
                                    <span className={`price ${fadeClass}`}>
                                        {getPrice(item)}
                                    </span>
                                </h3>
                            ) : (
                                <>
                                    <h3>{item.title}</h3>
                                    <div className={`price ${fadeClass}`}>
                                        {getPrice(item)}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Rates
