import React from 'react'

import Card from '../card/Card'
import LatestNewsAdvertorialCard from '../latest-news-advertorial-card/LatestNewsAdvertorialCard'
import { ArticleListItemProps } from './ArticleListItem.props'

const ArticleListItem: React.FC<ArticleListItemProps> = ({
    article,
    dataTrackable = '',
    showImage = true,
    withTopSeparator = true,
    withBottomSeparator = false,
    featured,
}) => {
    let type =
        article.advertorial_sponsor && article.advertorial_sponsor.length
            ? 'advertorial'
            : 'article'
    const Component = type === 'article' ? Card : LatestNewsAdvertorialCard

    if (article.__typename === 'EventHub') {
        type = 'event'
    }

    if (article.partner_content && article.partner_content.length) {
        type = 'partner'
    }

    return (
        <div
            className={`o-grid-row latest-news__container ${type}-news-item__column`}
            key={article.id}
        >
            {withTopSeparator && (
                <div
                    className='latest-news__separator'
                    data-o-grid-colspan='12'
                />
            )}
            <Component
                featured={featured}
                content={article}
                className={`latest-news${featured ? '__featured' : ''}`}
                type={type}
                leftColSpan='0 M3'
                rightColSpan='12 M9'
                teaserClass='stream'
                showImage={showImage}
                dataTrackable={dataTrackable || ''}
            />
            {withBottomSeparator && (
                <div
                    className='latest-news__separator'
                    data-o-grid-colspan='12'
                />
            )}
        </div>
    )
}

export default ArticleListItem
