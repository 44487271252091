import React, { FC } from 'react'

import DateComponent from '../date/Date'
import MPU from '../mpu/MPU'
import { fireEvent } from '../tracking/Tracking'

interface ArticleMetadataProps {
    date: string
    author?: Array<{ name: string }>
    image?: {
        image: string
    }
    noContainer?: boolean
    isPartnerContent?: boolean
    byline?: string
}

const ArticleMetadata: FC<ArticleMetadataProps> = (
    props = {
        date: '',
        noContainer: false,
        author: undefined,
        image: undefined,
        isPartnerContent: false,
        byline: '',
    }
) => {
    const className = props.noContainer ? '' : 'o-grid-container'

    const printArticle = () => {
        fireEvent(
            {
                category: 'cta',
                action: 'click',
                'data-trackable': 'Print button',
                context: {
                    text: 'Print',
                    url: window.location.href,
                    className: 'ft-print-icon',
                },
            },
            true
        )

        window.print()
    }

    const byline = props.byline || ''

    const colspanClasses = props.isPartnerContent
        ? '12 Mpush1 Lpush2 M10 L8'
        : '12 Mpush1 M10 L7'

    const Authors = () => {
        return (
            <div
                className='article-metadata__authors'
                dangerouslySetInnerHTML={{
                    __html: byline,
                }}
            />
        )
    }

    return (
        <div className={`${className} article-metadata-container`}>
            <div className='article-metadata o-grid-row'>
                <div
                    className='article-metadata__text'
                    data-o-grid-colspan={colspanClasses}
                >
                    {!props.isPartnerContent ? <Authors /> : null}

                    <DateComponent
                        id='article-metadata-date'
                        className='article-metadata__date'
                        dateTime={props.date}
                    />
                    <img
                        onClick={printArticle}
                        className='article-metadata__print'
                        src='https://static.fdiintelligence.com/assets/common/icon-print.svg'
                    />
                </div>
                {!props.image && (
                    <div
                        className='article-metadata__advertisement'
                        data-o-grid-colspan='0 Lpull0 L3'
                    >
                        <MPU type='mid' initAds />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ArticleMetadata
