import React, { FC } from 'react'

import { getStreamUrlFromTag } from '../../utils/helpers'

import DateComponent from '../date/Date'
import { Report, Tag } from '../../types'

const ReportHeader: FC<Report> = props => {
    let tag = props.tags && props.tags.nominated
    if (!tag) {
        tag = props.tags && props.tags.web_section && props.tags.web_section[0]
    }

    const streamUrl = getStreamUrlFromTag(tag as Tag) || '#'

    return (
        <>
            <section className='report-header'>
                <div className='o-grid-container'>
                    <div className='o-grid-row'>
                        <div
                            className='report-header__info'
                            data-o-grid-colspan='12 M6'
                        >
                            <div className='report-header__nominated-tag'>
                                <a href={streamUrl}>{tag.label}</a>
                            </div>
                            <h2 className='report-header__title'>
                                {props.title}
                            </h2>
                            <div className='report-header__labels'>
                                <a
                                    className='report-header__label'
                                    href='/reports-and-whitepapers'
                                >
                                    Special Report
                                </a>
                            </div>
                            <div
                                className='report-header__description'
                                dangerouslySetInnerHTML={{
                                    __html: props.description,
                                }}
                            ></div>
                            <div className='report-header__publication-date'>
                                <DateComponent
                                    id='report-header__publication-date'
                                    dateTime={props.publish_date}
                                />
                            </div>
                        </div>
                        <div
                            className='report-header__image'
                            data-o-grid-colspan='12 M6'
                        >
                            <img
                                src={props.image && props.image.image}
                                alt={props.image && props.image.caption}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReportHeader
