import React, { FC } from 'react'

import ACastIframe from '../acast-iframe/ACastIframe'

interface ArticleACastProps {
    acast_podcast_id: string | null
}

const ArticleAcast: FC<ArticleACastProps> = (
    props = {
        acast_podcast_id: null,
    }
) => {
    if (!props.acast_podcast_id) {
        return null
    }

    return (
        <div className='article-acast o-grid-row'>
            <div data-o-grid-colspan='12 Mpush1 M10 L7'>
                <ACastIframe podcastId={props.acast_podcast_id} />
            </div>
        </div>
    )
}

export default ArticleAcast
