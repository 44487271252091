import React, { useRef, useEffect, useContext } from 'react'

import LoggedInContext from '../../context/LoggedInContext'

import NavList from './NavList'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavItem = ({ level = 1, item }: any) => {
    const { isLoggedIn } = useContext(LoggedInContext)
    const listItemRef = useRef()

    useEffect(() => {
        function mutationObserverCallback(mutationList: MutationRecord[]) {
            const ariaExpandedMutation = mutationList
                .filter(isAriaExpandedMutation)
                .pop()

            if (ariaExpandedMutation) {
                const ariaExpandedValue = (
                    ariaExpandedMutation.target as Element
                ).getAttribute('aria-expanded')
                const dataTrackableValue =
                    ariaExpandedValue === 'true' ? 'closed' : 'open'
                const button = (
                    ariaExpandedMutation.target as Element
                ).querySelector('button')

                if (button) {
                    button.setAttribute('data-trackable', dataTrackableValue)
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function isAriaExpandedMutation(mutation: any) {
                return (
                    mutation.type === 'attributes' &&
                    mutation.attributeName === 'aria-expanded'
                )
            }
        }

        let mutationObserver = null
        try {
            mutationObserver = new MutationObserver(mutationObserverCallback)
        } catch (e) {
            // E.g. Node environment (tests running)
            mutationObserver = null
        }

        if (mutationObserver && listItemRef.current) {
            mutationObserver.observe(listItemRef.current, { attributes: true })
        }

        return () => {
            if (mutationObserver) {
                mutationObserver.disconnect()
            }
        }
    }, [])

    /*
     *  Dynamically build attributes for the <li> element, doing it here helps to keep the markup tidy
     */
    const getListItemAttributes = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attributes: any = {
            className: [
                'nav-item',
                item.capitalize ? 'capitalize' : '',
                ...(item.desktopHidden ? ['desktop-hidden'] : []),
                ...(item.items || item.desktopLast
                    ? ['nav-item--has-no-right-border']
                    : []),
            ].join(' '),
            ref: listItemRef,
        }

        if (item.items) {
            attributes['data-o-header-services-level'] = level
        } else {
            attributes.className += ' nav-item--single'
        }

        return attributes
    }

    /*
     *  Dynamically build attributes for the <a> element, doing it here helps to keep the markup tidy
     *  We are adding the data-o-tracking-skip-queue to ensure that click events fire immediately, rather than on the next
     *  page load, which for MVP, could be on a different domain, which would lead to lost data
     */
    const getAnchorAttributes = () => {
        return {
            href: item.route,
            target: item.target || '_self',
            className: level === 1 ? 'nav-anchor' : 'sub-nav-anchor',
            'data-o-tracking-skip-queue': 'true',
            ...(item.isCurrent ? { 'aria-current': 'true' } : {}),
        }
    }

    /*
     *  Dynamically build attributes for the drop-down button element, doing it here helps to keep the markup tidy
     */
    const getButtonAttributes = () => {
        return {
            className: 'o-header-services__drop-down-button nav-toggler-button',
            type: 'button',
            name: 'button',
            'aria-label': 'Toggle dropdown menu',
            'data-trackable': 'open',
        }
    }

    /*
     *  Dynamically build attributes for the sub navigation list, doing it here helps to keep the markup tidy
     */
    const getNavListAttributes = () => {
        return {
            items: item.items || [],
            level: level + 1,
            isLoggedIn,
        }
    }

    const listItemAttributes = getListItemAttributes()
    const anchorAttributes = getAnchorAttributes()
    const buttonAttributes = getButtonAttributes()
    const navListAttributes = getNavListAttributes()
    const itemNamesNotToShow = isLoggedIn
        ? ['Log in or register']
        : ['My account', 'Log out']

    if (itemNamesNotToShow.includes(item.name)) {
        return null
    }

    const itemsToHide = ['Log in or register', 'My account', 'Log out']

    if (itemsToHide.includes(item.name)) {
        return null
    }

    return (
        <li {...listItemAttributes}>
            {item.new && !item.pulseDot ? <div className='new'>New</div> : null}
            {item.new && item.pulseDot ? (
                <div className='new-dot'>
                    <span>New products</span>
                </div>
            ) : null}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <a {...(anchorAttributes as any)}>{item.name}</a>
            {item.items && (
                <>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <button {...(buttonAttributes as any)} />
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <NavList {...(navListAttributes as any)} />
                </>
            )}
        </li>
    )
}

export default NavItem
