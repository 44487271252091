import React from 'react'

import { __w } from '../../utils/helpers'

interface NormalProps {
    section: {
        image: string
        title: string
        paragraph: string
        reverse: boolean
        italic: boolean
    }
    isRight: boolean
}

export const Normal = ({ section, isRight }: NormalProps) => {
    const rightSection =
        (isRight && !section.reverse) || (!isRight && section.reverse)
            ? 'right'
            : ''
    const paragraphStyle: React.CSSProperties = {}
    if (section.italic) {
        paragraphStyle.fontStyle = 'italic'
        paragraphStyle.minWidth = '20rem'
    }

    return (
        <div className={`normal-section ${rightSection}`}>
            {section.image && (
                <div className='image'>
                    <img src={section.image} alt={section.title} />
                </div>
            )}
            <div className='text'>
                {section.title && <h2>{__w(section.title)}</h2>}
                {section.paragraph && (
                    <p style={paragraphStyle}>{__w(section.paragraph)}</p>
                )}
            </div>
        </div>
    )
}

export default Normal
