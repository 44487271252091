import React, { useState } from 'react'
import Wedge from '../wedge/Wedge'
import PageTitle from '../page-title/PageTitle'
import { commercialContent } from '../../assets/page_data/commercial'
import { PAGE_TITLE_COMMERCIAL } from '../../assets/constants'

export default function CommercialLayout() {
    const { intro, stats, tabs } = commercialContent.sections
    const [activeTab, setActiveTab] = useState(0)
    return (
        <Wedge
            isNotPulledUp
            isFlatTop
            isFlatBottom
            styling={commercialContent.styling}
            options={commercialContent.options}
            className='commercial'
        >
            <PageTitle
                title={PAGE_TITLE_COMMERCIAL}
                titleModifier='no-padding'
            />
            {intro.map((section, index) => (
                <p className='intro-text' key={index}>
                    {section}
                </p>
            ))}
            <div className='stats-container'>
                {stats.map((stat, index) => (
                    <div className='stat' key={index}>
                        <div className='stat-number'>{stat.value}</div>
                        <div className='stat-text'>{stat.label}</div>
                    </div>
                ))}
            </div>
            <div
                className='tabs-container'
                data-trackable-section='commercial-tabs'
            >
                <div className='tab'>
                    {tabs.map((tab, index) => (
                        <div
                            onClick={() => setActiveTab(index)}
                            key={index}
                            className={`tab-title ${index === activeTab ? 'active' : ''}`}
                        >
                            {tab.label}
                        </div>
                    ))}
                </div>
                <div className='tab-content--wrapper'>
                    <div className={`tab-content`}>
                        {tabs[activeTab].component || ''}
                    </div>
                </div>
            </div>
            <div
                className='contacts-container'
                data-trackable-section='commercial-contacts'
            >
                <a
                    href='https://www.fdiintelligence.com/content/download/83456/2723223/version/1/file/fDi+Media+Kit+-+2024+%28GBP%29.pdf'
                    className='contacts-container__download'
                >
                    Download the 2024 fDi Media pack.
                </a>
            </div>
        </Wedge>
    )
}
