/**
 * Simple logging function, for temporary use until we know how our production logs will be handled
 * @param input - The text to be logged
 * @param type - The severity of the message
 * @param channel - Currently unused, intended to give each app / service the ability to send logs to its own location
 */
const writeLog = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: any,
    type = 'info'
    // channel = 'default',
    // old = false
) => {
    const types = {
        info: '\x1b[0m',
        debug: '\x1b[34m',
        warning: '\x1b[33m',
        error: '\x1b[31m',
        table: '',
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!types.hasOwnProperty(type)) {
        type = 'info'
    }

    if (process.env.NODE_ENV !== 'test') {
        if (type !== 'table') {
            console.log(
                types[type as keyof typeof types],
                new Date().toLocaleString() + ': ' + input
            )
        } else {
            console.table(input)
        }
    }
}

export default writeLog
