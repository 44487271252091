import { getStreamUrlFromTag } from '../../utils/helpers'
import React from 'react'
export interface WebSectionProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tags: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultSection?: any
    type: string
    partner?: string
}

const WebSection = ({
    tags = {},
    type = 'article',
    defaultSection = {
        label: 'Locations',
        path: '/global',
    },
    partner = '',
}: WebSectionProps) => {
    let tag = tags && tags.nominated
    let url

    if (!tag) {
        tag = tags && tags.web_section && tags.web_section[0]
    }

    if (tag) {
        url = getStreamUrlFromTag(tag)
    } else {
        url = defaultSection.path
        tag = {
            label: defaultSection.label,
        }
    }

    return (
        <span className={`web-section web-section--${type}`}>
            {type === 'article' && <a href={url}>{tag.label}</a>}
            {type === 'partner' &&
                `Partner Content ${partner ? `by ${partner}` : ''}`}
            {type === 'advertorial' && 'Partner Content'}
        </span>
    )
}

export default WebSection
