import { useEffect } from 'react'

export const useStickyNav = (navRef: React.RefObject<HTMLElement>) => {
    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current) {
                const sticky =
                    navRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    navRef.current.offsetHeight
                if (window.pageYOffset >= sticky) {
                    navRef.current.classList.add('fixed')
                } else {
                    navRef.current.classList.remove('fixed')
                }
            }
        }
        const handleResize = () => {
            if (navRef.current) {
                handleScroll()
            }
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
}

export default useStickyNav
