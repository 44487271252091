import React from 'react'
import { CurrencyProvider } from '../context/CurrencyContext'
import { useSectionCounter } from '../hooks/useSectionCounter'
import Hero from '../map-component/components/Hero'
import { MapComponent } from '../map-component/MapComponent'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AdvertisingOpportunitiesComponent = ({ product }: any) => {
    const { sectionCounter, incrementSectionCounter } = useSectionCounter()

    return (
        <CurrencyProvider>
            <div className={`o-grid-container full-width-bg ${product.link}`}>
                <Hero product={product} />
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {product.sections.map((section: any, index: any) => (
                    <MapComponent
                        section={section}
                        product={product}
                        counter={sectionCounter}
                        setCounter={incrementSectionCounter}
                        key={section.title}
                        index={index}
                    />
                ))}
            </div>
        </CurrencyProvider>
    )
}

export default AdvertisingOpportunitiesComponent
