import React, { FC } from 'react'

import DateComponent from '../date/Date'
import { ArticleRanking } from '../../types'

export interface ArticleRankingProps {
    ranking: ArticleRanking
}

const ArticleRanking: FC<ArticleRankingProps> = ({ ranking }) => {
    return (
        <div className='article-ranking-container o-grid-container'>
            <div className='o-grid-row'>
                <div data-o-grid-colspan='12 M10 Mpush1 L7 Lpush1'>
                    <div className='article-ranking'>
                        <div className='article-ranking__heading'>
                            <h3>How to enter</h3>
                        </div>
                        <div className='article-ranking__info o-grid-row'>
                            {ranking.closing_date && (
                                <div
                                    className='article-ranking__closing-date'
                                    data-o-grid-colspan='12 M6'
                                >
                                    <div>Closing date for submissions</div>
                                    <DateComponent
                                        id='article-ranking-closing-date'
                                        dateTime={ranking.closing_date}
                                    />
                                </div>
                            )}
                            {ranking.publish_date && (
                                <div
                                    className='article-ranking__publication-date'
                                    data-o-grid-colspan='12 M6'
                                >
                                    <div>Results published</div>
                                    <DateComponent
                                        id='article-ranking-publication-date'
                                        dateTime={ranking.publish_date}
                                        format='MMMM yyyy'
                                    />
                                </div>
                            )}
                            <div
                                className='article-ranking__contact'
                                data-o-grid-colspan='12'
                            >
                                <div>Contact</div>
                                <div className='article-ranking__contact-email'>
                                    {ranking.contact.email}
                                </div>
                                {ranking.contact.phone && (
                                    <div className='article-ranking__contact-phone'>
                                        {ranking.contact.phone}
                                    </div>
                                )}
                            </div>
                        </div>
                        {ranking.submission_url && (
                            <div className='article-ranking__button'>
                                <a
                                    className='button-link'
                                    href={ranking.submission_url}
                                    data-trackable='Rankings - Enter'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Take the survey to apply now
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleRanking
