import React, { FC } from 'react'

interface TableProps {
    title: string
    csv: string
    source?: string
}

const Table: FC<TableProps> = ({ title, csv, source }) => {
    const rows = csvToArray(csv)

    return rows.length > 0 ? (
        <div className='table-component'>
            <div className='o-table-container'>
                <div className='o-table-overlay-wrapper'>
                    <div className='o-table-scroll-wrapper'>
                        <table
                            className='o-table o-table--horizontal-lines o-table--responsive-overflow o-table--sortable'
                            data-o-component='o-table'
                            data-o-table-responsive='overflow'
                        >
                            <caption>{title}</caption>
                            <thead>
                                <tr>
                                    {rows[0].map((header, headerIndex) => (
                                        <th
                                            scope='col'
                                            role='columnheader'
                                            className='table__header'
                                            key={headerIndex}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.slice(1).map((row, rowIndex) => (
                                    <tr className='table__row' key={rowIndex}>
                                        {row.map((data, dataIndex) => (
                                            <td
                                                className='table__data'
                                                key={`${dataIndex}`}
                                            >
                                                {data}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            {source && (
                                <tfoot>
                                    <tr>
                                        <td
                                            colSpan={rows[0].length}
                                            className='o-table-footnote'
                                        >
                                            Source: {source}
                                        </td>
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default Table

export function csvToArray(csv: string) {
    return typeof csv === 'string'
        ? csv.split('\n').map(row => row.split(','))
        : []
}
