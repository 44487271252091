import React from 'react'

import Card from '../card/Card'

export interface LatestNewsAdvertorialCardProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any[]
    type: string
    dataTrackable: string
}

const LatestNewsAdvertorialCard: React.FC<
    LatestNewsAdvertorialCardProps
> = props => {
    return (
        <>
            <Card
                className='latest-news-advertorial card--small'
                content={props.content}
                type={props.type}
                leftColSpan='0 M3'
                rightColSpan='12 M9'
                teaserClass='inline'
                dataTrackable={props.dataTrackable}
            />
        </>
    )
}

export default LatestNewsAdvertorialCard
