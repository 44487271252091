import React from 'react'

export interface TextProps {
    section: {
        title: string
        paragraph: string
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

export const Text = ({ section }: TextProps) => {
    return (
        <div className={`textSection`}>
            <div className='text'>
                {section.title && <h2>{section.title}</h2>}
                {section.paragraph && (
                    <div
                        dangerouslySetInnerHTML={{ __html: section.paragraph }}
                    />
                )}
            </div>
        </div>
    )
}

export default Text
