import React from 'react'

import PropTypes from 'prop-types'

export interface SectionTitleProps {
    titleContent: string | React.ReactNode
    colSpan?: string
    noBleed?: boolean
}

const SectionTitle = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    noBleed = false,
    colSpan,
    titleContent,
}: SectionTitleProps) => {
    return (
        <div
            className='section__title--row'
            data-o-grid-colspan={colSpan ? colSpan : '12'}
        >
            <div className='section__title'>
                <h4 className='section__title__heading'>{titleContent}</h4>
            </div>
        </div>
    )
}

SectionTitle.propTypes = {
    titleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    noBleed: PropTypes.bool,
}

export default SectionTitle
