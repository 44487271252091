import React, { FC, useEffect } from 'react'
import moment from 'moment'

export interface ODateProps {
    dateTime: string
    format?: string
    className?: string
    id?: string
    isClientSide?: boolean
}

const ODate: FC<ODateProps> = ({
    dateTime,
    format,
    className,
    id,
    isClientSide = false,
}) => {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const oDate = require('@financial-times/o-date/browser')
        oDate.init()
    }, [])

    const formattedDate = moment(dateTime).format(format || 'MMMM D, yyyy')
    return (
        <>
            {!isClientSide ? (
                <time
                    dateTime={formattedDate}
                    className={className || 'o-date'}
                    id={id}
                >
                    {formattedDate}
                </time>
            ) : (
                <time
                    id={id}
                    data-o-component='o-date'
                    className={className || 'o-date'}
                    dateTime={dateTime}
                    data-o-date-format={format || 'MMMM d, yyyy'}
                />
            )}
        </>
    )
}

export default ODate
