import React, { FC } from 'react'

import MPU from '../mpu/MPU'

interface ArticleHeaderImageProps {
    image?: {
        image: string
    }
    teaser_image_caption?: string
}

const ArticleHeaderImage: FC<ArticleHeaderImageProps> = (
    props = {
        image: undefined,
        teaser_image_caption: '',
    }
) => {
    const caption = props.teaser_image_caption

    return props.image ? (
        <div className='article-header-regular-image'>
            <div className='article-header__image o-grid-row'>
                <div
                    className='article-header__image-container'
                    data-o-grid-colspan='12 Mpush1 M10 L7'
                >
                    <img
                        className='article-header__image-file'
                        src={props.image.image}
                    />
                    {caption && (
                        <div
                            className='article-header__image-caption'
                            dangerouslySetInnerHTML={{ __html: caption }}
                        />
                    )}
                </div>
                <div
                    className='article-metadata__advertisement'
                    data-o-grid-colspan='0 Lpush8 L3'
                >
                    <MPU type='mid' initAds />
                </div>
            </div>
        </div>
    ) : null
}

export default ArticleHeaderImage
