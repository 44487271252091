/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import Image from '../image/Image'
import { SponsorType } from '../../types'

interface ArticleSponsorProps {
    advertorialSponsor?: Array<SponsorType> | null
    partnerContent?: Array<SponsorType> | null
}

const ArticleSponsor: FC<ArticleSponsorProps> = (
    { advertorialSponsor, partnerContent } = {
        advertorialSponsor: [],
        partnerContent: [],
    }
) => {
    const sponsor =
        advertorialSponsor && advertorialSponsor.length
            ? advertorialSponsor[0]
            : null
    const partner =
        partnerContent && partnerContent.length ? partnerContent[0] : null

    if (!sponsor && !partner) {
        return null
    }

    const sponsorContent = sponsor || partner
    const isPartner = sponsor ? false : !!partner

    const ImageStyle = {
        display: 'block',
        maxHeight: '100px',
        height: 'auto',
        width: 'auto',
        margin: 0,
        float: 'right',
    }

    const displayText = isPartner ? 'Partner Content:' : 'Supported by:'

    return (
        <>
            <div className='o-grid-container'>
                <div className='o-grid-row'>
                    <div
                        className='sponsor-container'
                        data-o-grid-colspan='12 Mpush1 M10 L7'
                    >
                        <div className='sponsor-container__content'>
                            <div
                                className='sponsor-container__text'
                                data-o-grid-colspan='S3'
                            >
                                {displayText}
                            </div>
                            <div
                                className='sponsor-container__logo'
                                data-o-grid-colspan='S9'
                            >
                                <Image
                                    src={sponsorContent?.image as string}
                                    alt={sponsorContent?.name as string}
                                    style={ImageStyle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArticleSponsor
