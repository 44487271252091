/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import colours from '../../utils/colours'
import writeLog from '../../utils/logging'

interface ArticleTeaserProps {
    teaser: string
    white?: boolean
    isPartnerContent?: boolean
}

const ArticleTeaser: FC<ArticleTeaserProps> = (
    props = {
        teaser: '',
        white: false,
        isPartnerContent: false,
    }
) => {
    if (!props.teaser) {
        return null
    }
    let teaserColour

    try {
        teaserColour = colours.find(colour => {
            return colour.name === 'fdi-off-black'
        })?.hex
    } catch (e) {
        writeLog(e, 'error')
    }

    const text = props.teaser.replace(/<p>|<\/p>/g, '')
    const color = props.white ? 'white' : teaserColour
    const colspanClasses = props.isPartnerContent
        ? '12 Mpush1 Lpush2 M10 L8'
        : '12 Mpush1 M10 L7'

    return (
        <div
            className='article-teaser o-grid-row'
            style={
                {
                    '--article-teaser-color': color,
                } as any
            }
        >
            <h3
                className='article-teaser__text'
                data-o-grid-colspan={colspanClasses}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </div>
    )
}

export default ArticleTeaser
