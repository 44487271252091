import React, { FC } from 'react'

interface ArticleAuthorProps {
    isWhite?: boolean
    author?: string
}

const ArticleAuthor: FC<ArticleAuthorProps> = ({
    author = '',
    isWhite = false,
}: ArticleAuthorProps) => {
    const colour = isWhite ? 'white' : '$fdi-off-black'

    return (
        <div className='o-grid-row'>
            <h3
                className='article-teaser__author'
                data-o-grid-colspan='12 Mpush1 M10 L7'
                style={{
                    margin: '8px 0 24px 0',
                    color: `${colour}`,
                }}
                dangerouslySetInnerHTML={{ __html: author || '' }}
            />
        </div>
    )
}

export default ArticleAuthor
