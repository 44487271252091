import React from 'react'

import { __w } from '../../utils/helpers'

export interface TitleBulletsColumnsProps {
    section: {
        title: string
        paragraph: string
        image: string
        items: { title: string; content: string }[]
    }
    isRight: boolean
}

export const TitleBulletsColumns: React.FC<TitleBulletsColumnsProps> = ({
    section,
    isRight,
}) => (
    <div className={`normal-with-bullets ${isRight ? 'right' : ''}`}>
        <div className='text'>
            <div className='columns'>
                <div className='first-column'>
                    {section.title && <h2>{__w(section.title)}</h2>}
                    {section.paragraph && <p>{__w(section.paragraph)}</p>}
                    {section.image && (
                        <img src={section.image} alt={section.title} />
                    )}
                </div>
                <ul>
                    {section.items.map((item, index) => (
                        <li key={index}>
                            {item.title && <h3>{__w(item.title)}</h3>}
                            {item.content && <p>{__w(item.content)}</p>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
)

export default TitleBulletsColumns
