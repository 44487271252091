const superText = {
    onlocation: 'fDi On Location',
    rankings: 'Rankings & Awards',
    inside: 'Inside fDi',
    globalLawyer: 'The Global Lawyer',
    freezone: 'Free Zone Focus',
    americas: 'View from the Americas',
    asia: 'View from Asia-Pacific',
    europe: 'View from Europe',
    middleEast: 'View from Middle East & Africa',
    Podcast: 'fDi Podcast',
}

export default superText
