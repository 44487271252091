/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import ArticleHeaderGeneric from './types/ArticleHeaderGeneric/ArticleHeaderGeneric'
import { Article } from '../../types'
import typesConfig from '../../assets/articleTypesConfig'

interface ArticleHeaderProps {
    type?: string
    colour?: string | null
    article: Article
    environment: {
        vimeoPlayerUrl: string
    }
}

const ArticleHeader: FC<ArticleHeaderProps> = props => {
    const type = props.type || 'editorial'

    const authorImageFilter = typesConfig[type as keyof typeof typesConfig]
        ? typesConfig[type as keyof typeof typesConfig].authorImageFilter
        : null
    const SelectedArticleHeader = typesConfig[type as keyof typeof typesConfig]
        ? typesConfig[type].component
        : ArticleHeaderGeneric

    return (
        <SelectedArticleHeader
            authorImageFilter={authorImageFilter}
            type={type}
            article={props.article}
            colour={props.colour}
            environment={props.environment}
        />
    )
}

export default ArticleHeader
