import { Request, Response } from 'express'
export const getToolkit = async () => {
    if (typeof window !== 'undefined') {
        throw new Error(
            'specialist-node-toolkit should not be used in the browser'
        )
    }
    return (await import('specialist-node-toolkit')).default
}

/**
 * Get the origin URL from the request
 * @param req - The request object
 * @returns The origin URL
 */
export const getOriginUrl = (req: Request) => {
    const host =
        (req ? req.headers['original-host'] : window.location.host) ||
        'localhost:8000'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const protocol = /^localhost(:\d+)?$/.test(host as string)
        ? 'http:'
        : 'https:'
    // return `${protocol}//${host}`
    return 'https://www.fdiintelligence.com'
}

/**
 * Get the state from the document
 * The state is a JSON object that is stored in the document as a script tag with the id 'state'
 * This function will try to parse the state and return it as an object
 * If the state is not a valid JSON object, it will return an empty object
 * @returns The state
 */
export const getState = () => {
    const state = document.getElementById('state')
    if (!state) return {}

    try {
        const stateContent = state.innerHTML
        if (typeof stateContent === 'object') return stateContent
        const parsedState = JSON.parse(decodeHtmlTags(stateContent))
        if (parsedState && typeof parsedState === 'object') {
            return parsedState
        }
        return {}
    } catch (e: unknown) {
        console.error('Error parsing state', e as Error)
        return {}
    }
}

/**
 * Encode a html tags
 * @param text - The string to encode
 * @returns The encoded string
 */
export const encodeHtmlTags = (text: string) =>
    text
        .replace(/</g, '&lt;') // Encode <
        .replace(/>/g, '&gt;') // Encode >
        .replace(/"/g, '&quot;') // Encode "
        .replace(/'/g, '&#39;') // Encode '

/**
 * Decode a html tags
 * @param text - The string to decode
 * @returns The decoded string
 */
export const decodeHtmlTags = (text: string) =>
    text
        .replace(/&lt;/g, '<') // Decode &lt;
        .replace(/&gt;/g, '>') // Decode &gt;
        .replace(/&quot;/g, '"') // Decode &quot;
        .replace(/&#39;/g, "'") // Decode &#39;

/**
 * Clean HTML tags from a string
 * @param text - The string to clean
 * @returns The cleaned string
 */
export const cleanHtmlTags = (text: string) =>
    text.replace(/<\/?[^>]+(>|$)/g, '')

export const getDefaultProps = async (req: Request, res: Response) => {
    const origin = getOriginUrl(req)
    return {
        environment: {
            vimeoPlayerUrl: process.env.VIMEO_PLAYER_URL || '',
            isNewCMPEnabled: process.env.IS_NEW_CMP_ENABLED || false,
            reCaptchaKey: process.env.RECAPTCHA_KEY || '',
            apolloServerUrl: process.env.APOLLO_SERVER_URL || '',
        },
        url: req.query.url,
        fullPageUrl: origin + req.path,
        referrer: req.query.referrer,
        origin,
        js: res.locals.assetsFileNames.js,
        css: res.locals.assetsFileNames.css,
        featureFlags: await (await getToolkit()).flags.getAll(),
    }
}

/**
 * Build a URL with optional query parameters
 * @param baseUrl - The base URL to build upon
 * @param options - Options containing queryParams
 * @returns The constructed URL string
 */
export const buildUrl = (
    baseUrl: string,
    options?: { queryParams?: Record<string, string> }
): string => {
    if (!options?.queryParams) {
        return baseUrl
    }

    const searchParams = new URLSearchParams()
    Object.entries(options.queryParams).forEach(([key, value]) => {
        searchParams.append(key, value)
    })

    const queryString = searchParams.toString()
    if (!queryString) {
        return baseUrl
    }

    const separator = baseUrl.includes('?') ? '&' : '?'
    return `${baseUrl}${separator}${queryString}`
}

// TO DO - revise this logic when doing the streams vanities
const specialTags = {
    Opinion: '/opinion',
    Podcast: '/fdi-podcast',
    News: '/news',
    Locations: '/global',
    'Rankings & Awards': '/rankings-and-awards',
    'Special Report': '/reports-and-whitepapers',
    'Free Zone Focus': '/opinion/free-zone-focus',
    'The Global Lawyer': '/opinion/the-global-lawyer',
    'Inside fDi': '/opinion/inside-fdi',
    'View from Middle East & Africa': '/opinion/view-from/middle-east-africa',
    'View from Europe': '/opinion/view-from/europe',
    'View from Asia-Pacific': '/opinion/view-from/asia-pacific',
    'View from Americas': '/opinion/view-from/americas',
}

/**
 * Returns a URL for the tag from a given tag object.
 *
 * @param {Object} tag A tag object
 * @param {Object} locationTags All tags that were selected
 * @return {String}
 */
export const getStreamUrlFromTag = (tag: {
    label: string
    path: string
    slug?: string
}) => {
    if (tag.slug) {
        return `/${tag.slug}`
    }

    if (!tag || !tag.label || !tag.path) {
        return null
    }

    const base = '/stream'

    if (specialTags[tag.label as keyof typeof specialTags]) {
        return encodeURI(specialTags[tag.label as keyof typeof specialTags])
    }

    if (tag.label === 'Advertorial') {
        return encodeURI(`${base}/partner-content`)
    }

    tag.path = tag.path.replace(/\s+/g, '-')
    if (new RegExp(/^Global\/?/).test(tag.path)) {
        return encodeURI(
            '/' + tag.path.substring(0, 1).toLowerCase() + tag.path.substring(1)
        )
    }

    if (new RegExp(/^(Award\/?|Ranking\/?)/).test(tag.path)) {
        return encodeURI(base + '/' + tag.label)
    }

    return encodeURI(base + '/' + tag.path)
}
