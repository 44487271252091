import React from 'react'

interface IframeProps {
    link: string
    className?: string
    style?: object
}

const Iframe = (props: IframeProps) => {
    const { style, link, className } = props

    return (
        <>
            <iframe
                className={className}
                src={link}
                frameBorder='0'
                // TODO: to be checked
                // lazyload='on'
                style={{ ...style, width: '100%' }}
            />
        </>
    )
}

export default Iframe
