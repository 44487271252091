/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

interface ArticleHeaderImageFullProps {
    image?: {
        image: string
    }
    caption: string
}

const ArticleHeaderImageFull: FC<ArticleHeaderImageFullProps> = (
    props = {
        image: undefined,
        caption: '',
    }
) => {
    if (!props.image) {
        return null
    }

    return (
        <div className='article-header-image-full-container'>
            <div className='article-header__image o-grid-row'>
                <img
                    className='article-header__image-file'
                    alt={props.caption}
                    src={props.image.image}
                />
            </div>
        </div>
    )
}

export default ArticleHeaderImageFull
