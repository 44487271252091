import React from 'react'

export type SectionCounter = { [key: string]: number }

export const useSectionCounter = () => {
    const sectionCounter = React.useRef<SectionCounter>({})
    const incrementSectionCounter = (sectionType: string) => {
        sectionCounter.current[sectionType] =
            (sectionCounter.current[sectionType] || 0) + 1
    }

    return { sectionCounter, incrementSectionCounter }
}
