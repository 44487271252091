import { ConfigOptions } from '../../types'

/**
 * Updates the oAds config and reinits the ads library to apply new changes
 * @param {object} configOptions - The additional options to append to the 'targeting' property
 */
export function updateAdsConfig(configOptions: ConfigOptions) {
    if (!configOptions) return

    const oAdsConfigElement = document.getElementById('data-o-ads-config')
    if (!oAdsConfigElement) return

    const oAdsConfig =
        JSON.parse(decodeURIComponent(oAdsConfigElement.innerHTML)) || {}

    if (!oAdsConfig.targeting) oAdsConfig.targeting = {}

    window.ft_pageview_id =
        window.ft_pageview_id || Math.round(Math.random() * 10000000000000000)
    configOptions.ft_pageview_id = window.ft_pageview_id

    oAdsConfig.targeting = { ...oAdsConfig.targeting, ...configOptions }
    oAdsConfigElement.innerHTML = JSON.stringify(oAdsConfig)

    window.oAds && window.oAds.init()
}
