import React from 'react'

import Card from '../card/Card'
import SectionTitle from '../section-title/SectionTitle'
import { Content, getCardType } from '../utils/helpers'
import { ArticleListItemProps } from '../article-list-item/ArticleListItem.props'

export interface ThreeArticlesBlockProps {
    title: string
    articles: ArticleListItemProps[]
    showDate?: boolean
    showImage?: boolean
}

const ThreeArticlesBlock: React.FC<ThreeArticlesBlockProps> = ({
    title,
    articles,
    showDate = false,
    showImage = false,
}) => {
    return articles.length !== 0 ? (
        <>
            <div className='three-articles-block'>
                <div className='o-grid-container'>
                    <div className='o-grid-row'>
                        <SectionTitle titleContent={title} />
                    </div>
                    <div className='o-grid-row'>
                        {articles.slice(0, 3).map((article, index, array) => {
                            const colspan = getCardContainerColspan(
                                array.length,
                                index
                            )
                            const className = getCardContainerClassName(
                                array.length,
                                index
                            )

                            return (
                                <div
                                    className={className}
                                    data-o-grid-colspan={colspan}
                                    key={article.id}
                                >
                                    <Card
                                        type={
                                            getCardType(
                                                article as unknown as Content
                                            ) as string
                                        }
                                        content={article}
                                        showDate={showDate}
                                        showImage={showImage}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    ) : null
}

export default ThreeArticlesBlock

function getCardContainerColspan(count: number, index: number) {
    switch (count) {
        case 1:
            return '12'
        case 2:
            return '6 M12'
        default:
            return index === 0 ? '12 M4' : '6 M4'
    }
}

function getCardContainerClassName(count: number, index: number) {
    const base = 'three-articles-block__card-container'
    const bigTitleModifier = `${base}--big-title`
    const horizontalModifier = `${base}--horizontal`
    const dividerModifier = `${base}--divider`
    const classes = [base]

    switch (count) {
        case 1:
            classes.push(bigTitleModifier)
            classes.push(horizontalModifier)
            break
        case 2:
            classes.push(horizontalModifier)
            break
        default:
            classes.push(dividerModifier)
            if (index === 0) {
                classes.push(bigTitleModifier)
            }
    }

    return classes.join(' ')
}
